import * as Types from '~anyx/shared/graphql/base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ImportsFileUploadQueryVariables = Types.Exact<{
  fileName: Types.Scalars['String']['input'];
}>;


export type ImportsFileUploadQuery = { readonly importsFileUpload: { readonly __typename?: 'UploadFilePayload', readonly fileName: string, readonly fileUrl: string, readonly signedUrl: string } };


export const ImportsFileUploadDocument = gql`
    query ImportsFileUpload($fileName: String!) {
  importsFileUpload(fileName: $fileName) {
    fileName
    fileUrl
    signedUrl
  }
}
    `;

/**
 * __useImportsFileUploadQuery__
 *
 * To run a query within a React component, call `useImportsFileUploadQuery` and pass it any options that fit your needs.
 * When your component renders, `useImportsFileUploadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImportsFileUploadQuery({
 *   variables: {
 *      fileName: // value for 'fileName'
 *   },
 * });
 */
export function useImportsFileUploadQuery(baseOptions: Apollo.QueryHookOptions<ImportsFileUploadQuery, ImportsFileUploadQueryVariables> & ({ variables: ImportsFileUploadQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ImportsFileUploadQuery, ImportsFileUploadQueryVariables>(ImportsFileUploadDocument, options);
      }
export function useImportsFileUploadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ImportsFileUploadQuery, ImportsFileUploadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ImportsFileUploadQuery, ImportsFileUploadQueryVariables>(ImportsFileUploadDocument, options);
        }
export function useImportsFileUploadSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ImportsFileUploadQuery, ImportsFileUploadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ImportsFileUploadQuery, ImportsFileUploadQueryVariables>(ImportsFileUploadDocument, options);
        }
export type ImportsFileUploadQueryHookResult = ReturnType<typeof useImportsFileUploadQuery>;
export type ImportsFileUploadLazyQueryHookResult = ReturnType<typeof useImportsFileUploadLazyQuery>;
export type ImportsFileUploadSuspenseQueryHookResult = ReturnType<typeof useImportsFileUploadSuspenseQuery>;
export type ImportsFileUploadQueryResult = Apollo.QueryResult<ImportsFileUploadQuery, ImportsFileUploadQueryVariables>;
export function refetchImportsFileUploadQuery(variables: ImportsFileUploadQueryVariables) {
      return { query: ImportsFileUploadDocument, variables: variables }
    }