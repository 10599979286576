import * as Types from '~anyx/shared/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductMediaUploadQueryVariables = Types.Exact<{
  fileName: Types.Scalars['String']['input'];
}>;


export type ProductMediaUploadQuery = { readonly productMediaUpload: { readonly __typename?: 'PublicUploadFilePayload', readonly fileName: string, readonly signedUrl: string } };


export const ProductMediaUploadDocument = gql`
    query ProductMediaUpload($fileName: String!) {
  productMediaUpload(fileName: $fileName) {
    fileName
    signedUrl
  }
}
    `;

/**
 * __useProductMediaUploadQuery__
 *
 * To run a query within a React component, call `useProductMediaUploadQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductMediaUploadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductMediaUploadQuery({
 *   variables: {
 *      fileName: // value for 'fileName'
 *   },
 * });
 */
export function useProductMediaUploadQuery(baseOptions: Apollo.QueryHookOptions<ProductMediaUploadQuery, ProductMediaUploadQueryVariables> & ({ variables: ProductMediaUploadQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductMediaUploadQuery, ProductMediaUploadQueryVariables>(ProductMediaUploadDocument, options);
      }
export function useProductMediaUploadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductMediaUploadQuery, ProductMediaUploadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductMediaUploadQuery, ProductMediaUploadQueryVariables>(ProductMediaUploadDocument, options);
        }
export function useProductMediaUploadSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProductMediaUploadQuery, ProductMediaUploadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProductMediaUploadQuery, ProductMediaUploadQueryVariables>(ProductMediaUploadDocument, options);
        }
export type ProductMediaUploadQueryHookResult = ReturnType<typeof useProductMediaUploadQuery>;
export type ProductMediaUploadLazyQueryHookResult = ReturnType<typeof useProductMediaUploadLazyQuery>;
export type ProductMediaUploadSuspenseQueryHookResult = ReturnType<typeof useProductMediaUploadSuspenseQuery>;
export type ProductMediaUploadQueryResult = Apollo.QueryResult<ProductMediaUploadQuery, ProductMediaUploadQueryVariables>;
export function refetchProductMediaUploadQuery(variables: ProductMediaUploadQueryVariables) {
      return { query: ProductMediaUploadDocument, variables: variables }
    }