import * as Types from '~anyx/shared/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MdAccountQueryVariables = Types.Exact<{
  id: Types.Scalars['Long']['input'];
}>;


export type MdAccountQuery = { readonly MdAccount: { readonly __typename?: 'MdAccount', readonly address1?: string | null, readonly address2?: string | null, readonly cityName?: string | null, readonly countryId: string, readonly createdAt: string, readonly hubspotCustomerId?: string | null, readonly id: string, readonly name: string, readonly netsuiteVendorId?: string | null, readonly phoneNumber: string, readonly phoneNumberCountryId: string, readonly product: Types.MdAccountProduct, readonly provinceId?: string | null, readonly status: Types.MdAccountStatus, readonly updatedAt: string, readonly websiteUrl?: string | null, readonly zipcode?: string | null } };


export const MdAccountDocument = gql`
    query MdAccount($id: Long!) {
  MdAccount(id: $id) {
    address1
    address2
    cityName
    countryId
    createdAt
    hubspotCustomerId
    id
    name
    netsuiteVendorId
    phoneNumber
    phoneNumberCountryId
    product
    provinceId
    status
    updatedAt
    websiteUrl
    zipcode
  }
}
    `;

/**
 * __useMdAccountQuery__
 *
 * To run a query within a React component, call `useMdAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useMdAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMdAccountQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMdAccountQuery(baseOptions: Apollo.QueryHookOptions<MdAccountQuery, MdAccountQueryVariables> & ({ variables: MdAccountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MdAccountQuery, MdAccountQueryVariables>(MdAccountDocument, options);
      }
export function useMdAccountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MdAccountQuery, MdAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MdAccountQuery, MdAccountQueryVariables>(MdAccountDocument, options);
        }
export function useMdAccountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MdAccountQuery, MdAccountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MdAccountQuery, MdAccountQueryVariables>(MdAccountDocument, options);
        }
export type MdAccountQueryHookResult = ReturnType<typeof useMdAccountQuery>;
export type MdAccountLazyQueryHookResult = ReturnType<typeof useMdAccountLazyQuery>;
export type MdAccountSuspenseQueryHookResult = ReturnType<typeof useMdAccountSuspenseQuery>;
export type MdAccountQueryResult = Apollo.QueryResult<MdAccountQuery, MdAccountQueryVariables>;
export function refetchMdAccountQuery(variables: MdAccountQueryVariables) {
      return { query: MdAccountDocument, variables: variables }
    }