import { useCheckOnboardingQuery } from '../graphql';

export const useOnboarding = ({
  storeId,
  skip = false,
}: {
  storeId?: string | null;
  skip?: boolean;
}) => {
  const { data, loading, error, refetch } = useCheckOnboardingQuery({
    skip: !storeId || skip,
    variables: {
      storeId: storeId || '',
    },
  });

  return {
    onboarding: {
      ...(data?.onboarding || {
        hasShopifyChannel: skip,
        hasRegisteredOrder: skip,
        hasInputUnitCost: skip,
        hasConnectedSaleChannel: skip,
        hasConnectedDatasourceChannel: skip,
        hasSaleChannelCostIndex: skip,
        hasAnalyticsReport: skip,
        hasAdvertisingDatasource: skip,
      }),
      inactiveSaleChannelCount: data?.onboarding.inactiveSaleChannelCount || 0,
    },
    loading,
    error,
    refetch,
  };
};
