import * as Types from '~anyx/shared/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductTagsInputQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.ProductTagsFilterInput>;
}>;


export type ProductTagsInputQuery = { readonly tagsV2: ReadonlyArray<string> };


export const ProductTagsInputDocument = gql`
    query ProductTagsInput($filter: ProductTagsFilterInput) {
  tagsV2(filter: $filter)
}
    `;

/**
 * __useProductTagsInputQuery__
 *
 * To run a query within a React component, call `useProductTagsInputQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductTagsInputQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductTagsInputQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useProductTagsInputQuery(baseOptions?: Apollo.QueryHookOptions<ProductTagsInputQuery, ProductTagsInputQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductTagsInputQuery, ProductTagsInputQueryVariables>(ProductTagsInputDocument, options);
      }
export function useProductTagsInputLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductTagsInputQuery, ProductTagsInputQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductTagsInputQuery, ProductTagsInputQueryVariables>(ProductTagsInputDocument, options);
        }
export function useProductTagsInputSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProductTagsInputQuery, ProductTagsInputQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProductTagsInputQuery, ProductTagsInputQueryVariables>(ProductTagsInputDocument, options);
        }
export type ProductTagsInputQueryHookResult = ReturnType<typeof useProductTagsInputQuery>;
export type ProductTagsInputLazyQueryHookResult = ReturnType<typeof useProductTagsInputLazyQuery>;
export type ProductTagsInputSuspenseQueryHookResult = ReturnType<typeof useProductTagsInputSuspenseQuery>;
export type ProductTagsInputQueryResult = Apollo.QueryResult<ProductTagsInputQuery, ProductTagsInputQueryVariables>;
export function refetchProductTagsInputQuery(variables?: ProductTagsInputQueryVariables) {
      return { query: ProductTagsInputDocument, variables: variables }
    }