import { ReactElement, RefAttributes, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import {
  GroupBase,
  Select,
  SelectForwardRef,
  SelectInstance,
  SelectProps,
  useMultiSelect,
} from '@any-ui-react/select';

import { DdiProductSegment } from '~anyx/shared/graphql';
import { BasicOption, ObjectUtils } from '~anyx/shared/utils';

type SegmentationOptions = BasicOption<string>;

interface SegmentationSelectProps<IsMulti extends boolean = boolean>
  extends SelectProps<SegmentationOptions, IsMulti> {
  isMulti?: IsMulti;
}

const SegmentationSelectGn = <IsMulti extends boolean = false>(
  { isMulti = true, ...rest }: SegmentationSelectProps,
  ref: SelectForwardRef<SegmentationOptions, IsMulti, GroupBase<SegmentationOptions>>
) => {
  const { t } = useTranslation();
  const options = ObjectUtils.arrayFromEnum(DdiProductSegment).map((option) => ({
    label: t('report.page.inventoryTracker.segment', { ns: 'report', segment: option }),
    value: option,
  }));

  const getSelectedLabel = (selectedCount: number) => {
    if (selectedCount === options.length) {
      return t('shared.action.all', {
        ns: 'shared',
        entity: t('marketplace.page.product.bulk.filter.placeholder.segmentation', {
          ns: 'marketplace',
        }),
      });
    }
    return t('shared.select.optionSelected', { ns: 'shared', amount: selectedCount });
  };

  const selectProps = useMultiSelect({
    options,
    getSelectedLabel,
  });

  return (
    <Select
      isClearable
      isSearchable
      placeholder={t('marketplace.page.product.bulk.filter.placeholder.segmentation', {
        ns: 'marketplace',
      })}
      {...rest}
      {...selectProps}
      isMulti={isMulti}
      ref={ref}
    />
  );
};

type SegmentationSelect<IsMulti extends boolean = boolean> = (
  props: SegmentationSelectProps &
    RefAttributes<SelectInstance<SegmentationOptions, IsMulti, GroupBase<SegmentationOptions>>>
) => ReactElement;

export const SegmentationSelect = forwardRef(SegmentationSelectGn) as <
  IsMulti extends boolean = boolean
>(
  p: SegmentationSelectProps<IsMulti> & {
    ref?: SelectForwardRef<SegmentationOptions, IsMulti, GroupBase<SegmentationOptions>>;
  }
) => ReactElement;
