import { useCallback } from 'react';

import { useSessionStorage } from '@mantine/hooks';

import { REPORT_CALENDAR_STORAGE_KEY } from '~anyx/shared/utils';

interface DateRange {
  startDate?: string;
  endDate?: string;
}

export const useReportSyncCalendar = () => {
  const [reportRange, setReportRange] = useSessionStorage<DateRange | null>(
    {
      getInitialValueInEffect: false,
      key: REPORT_CALENDAR_STORAGE_KEY,
      defaultValue: null,
      deserialize: JSON.parse,
      serialize: JSON.stringify,
    },
    {
      syncAcrossTabs: false,
    }
  );

  const { startDate, endDate } = reportRange || {};

  const setRange = useCallback(
    (range: DateRange) => {
      setReportRange(range);
    },
    [setReportRange]
  );

  return {
    range: {
      startDate,
      endDate,
    },
    setRange,
  };
};
