import { useNavigate } from 'react-router-dom';

import { Accordion, Button } from '@any-ui-react/core';
import classNames from 'classnames';

import { CheckCircleIcon, SuccessIcon } from '~anyx/shared/icons';

import styles from './setting-guide.module.css';
import { SettingGuideItem } from './useSettingGuideItems';

export const SettingGuideContent = ({ items }: { items: SettingGuideItem[] }) => {
  const navigate = useNavigate();
  return (
    <div className="border-gray-1 grid grid-cols-3 border-t pb-0">
      <Accordion chevron={null} className="col-span-2 pb-4 pt-2">
        {items.map((item, key) => {
          return (
            <Accordion.Item value={`item-${key}`} key={key} className="border-0">
              <Accordion.Control
                className={classNames(styles['accordion-control-inner'])}
                style={{ width: '100%' }}
              >
                <div className="flex items-center">
                  {item.isChecked ? (
                    <SuccessIcon className="h-4 w-4" />
                  ) : (
                    <CheckCircleIcon className="text-gray-2 h-4 w-4" />
                  )}
                  <p className="accordion-trigger-item-header ml-3">{item?.title}</p>
                </div>
              </Accordion.Control>
              <Accordion.Panel className={styles['accordion-panel-inner']}>
                <p className="text-gray-7 ml-7 mt-1 text-xs leading-8">{item?.content}</p>
                {item?.link?.pathname ? (
                  <Button
                    className="ml-7 mt-3"
                    onClick={() =>
                      navigate({
                        pathname: item?.link?.pathname || '',
                        search: item?.link?.search || '',
                      })
                    }
                    variant="default"
                  >
                    {item?.button}
                  </Button>
                ) : null}
              </Accordion.Panel>
            </Accordion.Item>
          );
        })}
      </Accordion>
      <img
        className="col-span-1 ml-auto h-36"
        src="/assets/common-onboarding/setting-guide.png"
        alt="setting guide"
      />
    </div>
  );
};
