import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Select, SelectProps } from '@any-ui-react/core';

import { AddressPart } from '~anyx/shared/utils';

import { useAddressFormContext } from '../context';
import { useProvinceOptions } from '../hooks';

export type RegionFieldProps = Partial<SelectProps> & {
  additionalData?: {
    label: string;
    value: string;
  }[];
};

export const RegionField = ({ additionalData = [], ...rest }: RegionFieldProps) => {
  const { t } = useTranslation();
  const { language, selectedCountry, useRegionId } = useAddressFormContext();
  const { control, getFieldState } = useFormContext();
  const { mappings } = useAddressFormContext();

  const { provinceOptions } = useProvinceOptions({
    useRegionId,
    country: selectedCountry,
    language,
  });

  const fieldName = mappings[AddressPart.REGION];
  if (!fieldName) {
    return;
  }

  return (
    <Controller
      name={fieldName}
      control={control}
      render={({ field: { ...props } }) => (
        <Select
          {...props}
          label={t('shared.field.province.name', { ns: 'shared' })}
          placeholder={t('shared.field.province.placeholder', { ns: 'shared' })}
          withinPortal={true}
          required
          searchable
          clearable
          data={[...additionalData, ...provinceOptions]}
          error={getFieldState(fieldName)?.error?.message?.toString()}
          {...rest}
        />
      )}
    />
  );
};
