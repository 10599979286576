import * as Types from '~anyx/shared/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductTagsSelectQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.ProductTagsFilterInput>;
}>;


export type ProductTagsSelectQuery = { readonly tagsV2: ReadonlyArray<string> };


export const ProductTagsSelectDocument = gql`
    query ProductTagsSelect($filter: ProductTagsFilterInput) {
  tagsV2(filter: $filter)
}
    `;

/**
 * __useProductTagsSelectQuery__
 *
 * To run a query within a React component, call `useProductTagsSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductTagsSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductTagsSelectQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useProductTagsSelectQuery(baseOptions?: Apollo.QueryHookOptions<ProductTagsSelectQuery, ProductTagsSelectQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductTagsSelectQuery, ProductTagsSelectQueryVariables>(ProductTagsSelectDocument, options);
      }
export function useProductTagsSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductTagsSelectQuery, ProductTagsSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductTagsSelectQuery, ProductTagsSelectQueryVariables>(ProductTagsSelectDocument, options);
        }
export function useProductTagsSelectSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProductTagsSelectQuery, ProductTagsSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProductTagsSelectQuery, ProductTagsSelectQueryVariables>(ProductTagsSelectDocument, options);
        }
export type ProductTagsSelectQueryHookResult = ReturnType<typeof useProductTagsSelectQuery>;
export type ProductTagsSelectLazyQueryHookResult = ReturnType<typeof useProductTagsSelectLazyQuery>;
export type ProductTagsSelectSuspenseQueryHookResult = ReturnType<typeof useProductTagsSelectSuspenseQuery>;
export type ProductTagsSelectQueryResult = Apollo.QueryResult<ProductTagsSelectQuery, ProductTagsSelectQueryVariables>;
export function refetchProductTagsSelectQuery(variables?: ProductTagsSelectQueryVariables) {
      return { query: ProductTagsSelectDocument, variables: variables }
    }