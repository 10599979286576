import * as Types from '~anyx/shared/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ProductSetVariantsVariantSelectorQueryVariables = Types.Exact<{
  filter: Types.ProductVariantForSetFilterInput;
  pageSize: Types.Scalars['Int']['input'];
  pageNumber: Types.Scalars['Int']['input'];
}>;


export type ProductSetVariantsVariantSelectorQuery = { readonly productVariantsForProductSet: { readonly __typename?: 'ProductVariantForSetView', readonly total: number, readonly productVariants: ReadonlyArray<{ readonly __typename?: 'ProductVariantForSetViewRecord', readonly productName: string, readonly variantName: string, readonly productVariantId: string, readonly media?: string | null, readonly productCode: string }> } };


export const ProductSetVariantsVariantSelectorDocument = gql`
    query ProductSetVariantsVariantSelector($filter: ProductVariantForSetFilterInput!, $pageSize: Int!, $pageNumber: Int!) {
  productVariantsForProductSet(
    filter: $filter
    pageSize: $pageSize
    pageNumber: $pageNumber
  ) {
    total
    productVariants {
      productName
      variantName
      productVariantId
      media
      productCode
    }
  }
}
    `;

/**
 * __useProductSetVariantsVariantSelectorQuery__
 *
 * To run a query within a React component, call `useProductSetVariantsVariantSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductSetVariantsVariantSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProductSetVariantsVariantSelectorQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      pageSize: // value for 'pageSize'
 *      pageNumber: // value for 'pageNumber'
 *   },
 * });
 */
export function useProductSetVariantsVariantSelectorQuery(baseOptions: Apollo.QueryHookOptions<ProductSetVariantsVariantSelectorQuery, ProductSetVariantsVariantSelectorQueryVariables> & ({ variables: ProductSetVariantsVariantSelectorQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ProductSetVariantsVariantSelectorQuery, ProductSetVariantsVariantSelectorQueryVariables>(ProductSetVariantsVariantSelectorDocument, options);
      }
export function useProductSetVariantsVariantSelectorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ProductSetVariantsVariantSelectorQuery, ProductSetVariantsVariantSelectorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ProductSetVariantsVariantSelectorQuery, ProductSetVariantsVariantSelectorQueryVariables>(ProductSetVariantsVariantSelectorDocument, options);
        }
export function useProductSetVariantsVariantSelectorSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ProductSetVariantsVariantSelectorQuery, ProductSetVariantsVariantSelectorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ProductSetVariantsVariantSelectorQuery, ProductSetVariantsVariantSelectorQueryVariables>(ProductSetVariantsVariantSelectorDocument, options);
        }
export type ProductSetVariantsVariantSelectorQueryHookResult = ReturnType<typeof useProductSetVariantsVariantSelectorQuery>;
export type ProductSetVariantsVariantSelectorLazyQueryHookResult = ReturnType<typeof useProductSetVariantsVariantSelectorLazyQuery>;
export type ProductSetVariantsVariantSelectorSuspenseQueryHookResult = ReturnType<typeof useProductSetVariantsVariantSelectorSuspenseQuery>;
export type ProductSetVariantsVariantSelectorQueryResult = Apollo.QueryResult<ProductSetVariantsVariantSelectorQuery, ProductSetVariantsVariantSelectorQueryVariables>;
export function refetchProductSetVariantsVariantSelectorQuery(variables: ProductSetVariantsVariantSelectorQueryVariables) {
      return { query: ProductSetVariantsVariantSelectorDocument, variables: variables }
    }