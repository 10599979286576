import * as Types from '~anyx/shared/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetStoreUnreadCountQueryVariables = Types.Exact<{
  input: Types.AnyChatGetStoreRequest;
}>;


export type GetStoreUnreadCountQuery = { readonly AnyChatStore?: { readonly __typename?: 'AnyChatStore', readonly chatCount: { readonly __typename?: 'OngoingCount', readonly unreadCount: number } } | null };


export const GetStoreUnreadCountDocument = gql`
    query GetStoreUnreadCount($input: AnyChatGetStoreRequest!) {
  AnyChatStore(input: $input) {
    chatCount {
      unreadCount
    }
  }
}
    `;

/**
 * __useGetStoreUnreadCountQuery__
 *
 * To run a query within a React component, call `useGetStoreUnreadCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStoreUnreadCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStoreUnreadCountQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetStoreUnreadCountQuery(baseOptions: Apollo.QueryHookOptions<GetStoreUnreadCountQuery, GetStoreUnreadCountQueryVariables> & ({ variables: GetStoreUnreadCountQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStoreUnreadCountQuery, GetStoreUnreadCountQueryVariables>(GetStoreUnreadCountDocument, options);
      }
export function useGetStoreUnreadCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStoreUnreadCountQuery, GetStoreUnreadCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStoreUnreadCountQuery, GetStoreUnreadCountQueryVariables>(GetStoreUnreadCountDocument, options);
        }
export function useGetStoreUnreadCountSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetStoreUnreadCountQuery, GetStoreUnreadCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetStoreUnreadCountQuery, GetStoreUnreadCountQueryVariables>(GetStoreUnreadCountDocument, options);
        }
export type GetStoreUnreadCountQueryHookResult = ReturnType<typeof useGetStoreUnreadCountQuery>;
export type GetStoreUnreadCountLazyQueryHookResult = ReturnType<typeof useGetStoreUnreadCountLazyQuery>;
export type GetStoreUnreadCountSuspenseQueryHookResult = ReturnType<typeof useGetStoreUnreadCountSuspenseQuery>;
export type GetStoreUnreadCountQueryResult = Apollo.QueryResult<GetStoreUnreadCountQuery, GetStoreUnreadCountQueryVariables>;
export function refetchGetStoreUnreadCountQuery(variables: GetStoreUnreadCountQueryVariables) {
      return { query: GetStoreUnreadCountDocument, variables: variables }
    }