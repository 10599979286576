import React from 'react';
import { RouteObject, Navigate } from 'react-router-dom';

import { Auth0Guard } from '~anyx/common/auth';
import { Restricted, Auth0Permissions } from '~anyx/common/permission';
import { ReportProductRoutePath } from '~anyx/common/routing';
import { UnauthorizedMessage } from '~anyx/shared/ui';

const ProductReportPage = React.lazy(() =>
  import('./pages').then((module) => ({ default: module.ProductReportPage }))
);

const ProductTemplate = React.lazy(() =>
  import('./templates').then((module) => ({ default: module.ProductTemplate }))
);

export const routes: RouteObject[] = [
  {
    path: 'products',
    element: (
      <Auth0Guard>
        <Restricted
          permissions={[[Auth0Permissions.CAN_USE_ANALYTICS]]}
          fallback={<UnauthorizedMessage />}
        >
          <ProductTemplate />
        </Restricted>
      </Auth0Guard>
    ),
    children: [
      {
        path: 'list',
        element: <ProductReportPage />,
      },
      {
        index: true,
        element: (
          <Navigate
            to={ReportProductRoutePath({ workspaceStoreId: null }).list().path}
            replace={true}
          />
        ),
      },
    ],
  },
];
