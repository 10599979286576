import * as Types from '~anyx/shared/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DdiHomeReportPieChartQueryVariables = Types.Exact<{
  input: Types.DdiHomeReportInput;
}>;


export type DdiHomeReportPieChartQuery = { readonly ddiHomeReportPieChart: { readonly __typename?: 'DdiHomeReportPieChartPayload', readonly currency: Types.ReportSupportedCurrency, readonly grossSales: ReadonlyArray<{ readonly __typename?: 'DdiHomeReportPieChartSectionPayload', readonly saleChannelType: Types.SaleChannelType, readonly value: string }>, readonly netSales: ReadonlyArray<{ readonly __typename?: 'DdiHomeReportPieChartSectionPayload', readonly saleChannelType: Types.SaleChannelType, readonly value: string }>, readonly orders: ReadonlyArray<{ readonly __typename?: 'DdiHomeReportPieChartSectionPayload', readonly saleChannelType: Types.SaleChannelType, readonly value: string }> } };


export const DdiHomeReportPieChartDocument = gql`
    query DDIHomeReportPieChart($input: DdiHomeReportInput!) {
  ddiHomeReportPieChart(input: $input) {
    currency
    grossSales {
      saleChannelType
      value
    }
    netSales {
      saleChannelType
      value
    }
    orders {
      saleChannelType
      value
    }
  }
}
    `;

/**
 * __useDdiHomeReportPieChartQuery__
 *
 * To run a query within a React component, call `useDdiHomeReportPieChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useDdiHomeReportPieChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDdiHomeReportPieChartQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDdiHomeReportPieChartQuery(baseOptions: Apollo.QueryHookOptions<DdiHomeReportPieChartQuery, DdiHomeReportPieChartQueryVariables> & ({ variables: DdiHomeReportPieChartQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DdiHomeReportPieChartQuery, DdiHomeReportPieChartQueryVariables>(DdiHomeReportPieChartDocument, options);
      }
export function useDdiHomeReportPieChartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DdiHomeReportPieChartQuery, DdiHomeReportPieChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DdiHomeReportPieChartQuery, DdiHomeReportPieChartQueryVariables>(DdiHomeReportPieChartDocument, options);
        }
export function useDdiHomeReportPieChartSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DdiHomeReportPieChartQuery, DdiHomeReportPieChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DdiHomeReportPieChartQuery, DdiHomeReportPieChartQueryVariables>(DdiHomeReportPieChartDocument, options);
        }
export type DdiHomeReportPieChartQueryHookResult = ReturnType<typeof useDdiHomeReportPieChartQuery>;
export type DdiHomeReportPieChartLazyQueryHookResult = ReturnType<typeof useDdiHomeReportPieChartLazyQuery>;
export type DdiHomeReportPieChartSuspenseQueryHookResult = ReturnType<typeof useDdiHomeReportPieChartSuspenseQuery>;
export type DdiHomeReportPieChartQueryResult = Apollo.QueryResult<DdiHomeReportPieChartQuery, DdiHomeReportPieChartQueryVariables>;
export function refetchDdiHomeReportPieChartQuery(variables: DdiHomeReportPieChartQueryVariables) {
      return { query: DdiHomeReportPieChartDocument, variables: variables }
    }