import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Button } from '@any-ui-react/core';
import classNames from 'classnames';

import { ConnectIntegrationIcon } from '~anyx/shared/icons';
import { Card } from '~anyx/shared/ui';

import { OnboardingContextType, useOnboardingCtx } from '../../context';

export enum NoIntegrationCheck {
  STORE = 'STORE',
  DATASOURCE = 'DATASOURCE',
}

export interface NoIntegrationProps {
  title: string;
  description: string;
  hasIntegration?: boolean;
  children?: ReactNode;
  className?: string;
  link: string;
  checks: (keyof OnboardingContextType['state'])[];
}

export const NoIntegration = ({
  title,
  description,
  children,
  hasIntegration,
  className,
  link,
  checks = [],
}: NoIntegrationProps) => {
  const { t } = useTranslation(['shared']);
  const navigate = useNavigate();
  const { state } = useOnboardingCtx();

  const onboardingCheck = useMemo(() => {
    return checks.every((check) => state[check]);
  }, [checks, state]);

  if (hasIntegration || (checks.length > 0 && onboardingCheck)) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{children}</>;
  }

  return (
    <Card className={classNames('lg:card shadow-none', className)} withPadding={false}>
      <div className="flex flex-col items-center pt-10">
        <div className="relative mb-7 h-[7.5rem] w-40">
          <img
            src="assets/common-onboarding/no-integration.png"
            alt={t('core.component.noIntegration.logo', { ns: 'core' })}
          />
          <ConnectIntegrationIcon className="absolute -top-3 left-[8.5rem] h-[3.5rem] w-[3.5rem]" />
        </div>
        <div className="mx-6 flex max-w-md flex-col items-center">
          <div className="pb-4 text-center text-base">{title}</div>
          <p className="text-gray-7 mx-4 pb-6 text-center">{description}</p>
        </div>
        <Button className="mb-12" variant="default" onClick={() => navigate(link)}>
          {t('shared.action.goTo', { entity: t('shared.entity.integration', { count: 2 }) })}
        </Button>
      </div>
    </Card>
  );
};
