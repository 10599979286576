import { ForwardedRef, ReactElement, ReactNode, forwardRef } from 'react';

import { Input, InputProps } from '@any-ui-react/core';
import cx from 'classnames';

import { ChevronDownIcon } from '~anyx/shared/icons';

export interface SelectButtonProps
  extends Omit<React.ComponentPropsWithoutRef<'button'>, 'placeholder' | 'value'>,
    Omit<InputProps, 'component' | 'ref'> {
  placeholder?: ReactNode;
  value?: ReactNode;
  active?: boolean;
}

type SelectButton = <HTMLButtonElement, SelectButtonProps>(
  props: SelectButtonProps,
  ref: ForwardedRef<HTMLButtonElement>
) => ReactElement;

export const SelectButton = forwardRef<HTMLButtonElement, SelectButtonProps>(
  ({ placeholder, value, active, error, classNames, disabled, ...props }, ref) => {
    return (
      <Input
        ref={ref}
        type="button"
        component="button"
        classNames={{
          ...classNames,
          input: cx(classNames?.input, 'truncate leading-loose', {
            '!border-blue-6': !error && active,
          }),
          rightSection: cx(classNames?.rightSection, 'w-10', {
            'text-gray-6': disabled,
          }),
        }}
        rightSection={<ChevronDownIcon className="h-4 w-4" />}
        error={error}
        disabled={disabled}
        {...props}
      >
        {placeholder && !value && <Input.Placeholder>{placeholder}</Input.Placeholder>}
        {value}
      </Input>
    );
  }
) as SelectButton;
