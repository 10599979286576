import * as Types from '~anyx/shared/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DdiHomeReportChartQueryVariables = Types.Exact<{
  input: Types.DdiHomeReportInput;
}>;


export type DdiHomeReportChartQuery = { readonly ddiHomeReportChart: { readonly __typename?: 'DdiHomeReportChartPayload', readonly currency: Types.ReportSupportedCurrency, readonly grossSales: ReadonlyArray<Record<string, any>>, readonly netSales: ReadonlyArray<Record<string, any>>, readonly orders: ReadonlyArray<Record<string, any>> } };


export const DdiHomeReportChartDocument = gql`
    query DDIHomeReportChart($input: DdiHomeReportInput!) {
  ddiHomeReportChart(input: $input) {
    currency
    grossSales
    netSales
    orders
  }
}
    `;

/**
 * __useDdiHomeReportChartQuery__
 *
 * To run a query within a React component, call `useDdiHomeReportChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useDdiHomeReportChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDdiHomeReportChartQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDdiHomeReportChartQuery(baseOptions: Apollo.QueryHookOptions<DdiHomeReportChartQuery, DdiHomeReportChartQueryVariables> & ({ variables: DdiHomeReportChartQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DdiHomeReportChartQuery, DdiHomeReportChartQueryVariables>(DdiHomeReportChartDocument, options);
      }
export function useDdiHomeReportChartLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DdiHomeReportChartQuery, DdiHomeReportChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DdiHomeReportChartQuery, DdiHomeReportChartQueryVariables>(DdiHomeReportChartDocument, options);
        }
export function useDdiHomeReportChartSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DdiHomeReportChartQuery, DdiHomeReportChartQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DdiHomeReportChartQuery, DdiHomeReportChartQueryVariables>(DdiHomeReportChartDocument, options);
        }
export type DdiHomeReportChartQueryHookResult = ReturnType<typeof useDdiHomeReportChartQuery>;
export type DdiHomeReportChartLazyQueryHookResult = ReturnType<typeof useDdiHomeReportChartLazyQuery>;
export type DdiHomeReportChartSuspenseQueryHookResult = ReturnType<typeof useDdiHomeReportChartSuspenseQuery>;
export type DdiHomeReportChartQueryResult = Apollo.QueryResult<DdiHomeReportChartQuery, DdiHomeReportChartQueryVariables>;
export function refetchDdiHomeReportChartQuery(variables: DdiHomeReportChartQueryVariables) {
      return { query: DdiHomeReportChartDocument, variables: variables }
    }