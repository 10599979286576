import * as Types from '~anyx/shared/graphql/base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type GetCsvExportJobResultQueryVariables = Types.Exact<{
  jobId: Types.Scalars['String']['input'];
}>;


export type GetCsvExportJobResultQuery = { readonly getCsvExportJobResult: { readonly __typename?: 'CsvExportJobResult', readonly url?: string | null, readonly state: Types.JobState, readonly jobId: string } };


export const GetCsvExportJobResultDocument = gql`
    query GetCsvExportJobResult($jobId: String!) {
  getCsvExportJobResult(jobId: $jobId) {
    url
    state
    jobId
  }
}
    `;

/**
 * __useGetCsvExportJobResultQuery__
 *
 * To run a query within a React component, call `useGetCsvExportJobResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCsvExportJobResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCsvExportJobResultQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useGetCsvExportJobResultQuery(baseOptions: Apollo.QueryHookOptions<GetCsvExportJobResultQuery, GetCsvExportJobResultQueryVariables> & ({ variables: GetCsvExportJobResultQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCsvExportJobResultQuery, GetCsvExportJobResultQueryVariables>(GetCsvExportJobResultDocument, options);
      }
export function useGetCsvExportJobResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCsvExportJobResultQuery, GetCsvExportJobResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCsvExportJobResultQuery, GetCsvExportJobResultQueryVariables>(GetCsvExportJobResultDocument, options);
        }
export function useGetCsvExportJobResultSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetCsvExportJobResultQuery, GetCsvExportJobResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetCsvExportJobResultQuery, GetCsvExportJobResultQueryVariables>(GetCsvExportJobResultDocument, options);
        }
export type GetCsvExportJobResultQueryHookResult = ReturnType<typeof useGetCsvExportJobResultQuery>;
export type GetCsvExportJobResultLazyQueryHookResult = ReturnType<typeof useGetCsvExportJobResultLazyQuery>;
export type GetCsvExportJobResultSuspenseQueryHookResult = ReturnType<typeof useGetCsvExportJobResultSuspenseQuery>;
export type GetCsvExportJobResultQueryResult = Apollo.QueryResult<GetCsvExportJobResultQuery, GetCsvExportJobResultQueryVariables>;
export function refetchGetCsvExportJobResultQuery(variables: GetCsvExportJobResultQueryVariables) {
      return { query: GetCsvExportJobResultDocument, variables: variables }
    }