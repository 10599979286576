import { ReactNode } from 'react';

import classNames from 'classnames';

import { ReportSupportedCurrency } from '~anyx/shared/graphql';
import { ArrowDownIcon, ArrowUpIcon } from '~anyx/shared/icons';
import { NumberWithPercentage, SkeletonLoader } from '~anyx/shared/ui';
import { EMPTY_DEFAULT } from '~anyx/shared/utils';

interface ReportCardProps {
  item: {
    title: string;
    value: ReactNode;
    percentage?: string | null;
    reverseColor?: boolean;
  };
  currency?: ReportSupportedCurrency;
  displayMode?: 'card' | 'grid';
  theme?: 'light' | 'dark';
  wrapperClassName?: string;
  loading?: boolean;
}

export const ReportCard = ({
  item,
  displayMode = 'card',
  theme = 'light',
  loading,
  ...rest
}: ReportCardProps) => {
  return (
    <div
      className={classNames('items-start justify-start rounded font-semibold leading-none', {
        'card rounded-none px-2.5 py-4 lg:rounded lg:p-4': displayMode === 'card',
        'border-l-gray-0 border-l border-opacity-10 px-2': displayMode === 'grid',
      })}
      {...rest}
    >
      <div
        className={classNames('mb-2 whitespace-nowrap text-sm lg:mb-1.5', {
          'text-gray-7': theme === 'light',
          'text-gray-5': theme === 'dark',
        })}
      >
        <SkeletonLoader loading={loading} variant="dark" className="h-3.5">
          {item.title}
        </SkeletonLoader>
      </div>

      <div className="mb-2 break-all text-sm lg:mb-1.5 lg:text-base">
        <SkeletonLoader loading={loading} variant="dark" className="h-3.5 lg:h-4">
          {item.value}
        </SkeletonLoader>
      </div>

      <SkeletonLoader loading={loading} variant="dark" className="h-5 pb-1.5 lg:pb-2">
        {item.percentage ? (
          <div
            className={classNames(
              'text-4xs lg:text-2xs flex w-fit items-start break-all rounded-3xl border border-opacity-20 bg-opacity-20 px-2 py-1',
              {
                'text-success border-success bg-success': item.reverseColor
                  ? +item.percentage < 0
                  : +item.percentage >= 0,
                'text-failure border-failure bg-failure': item.reverseColor
                  ? +item.percentage >= 0
                  : +item.percentage < 0,
              }
            )}
          >
            {+item.percentage >= 0 ? (
              <>
                <ArrowUpIcon className="mr-0.5 h-2.5 w-2.5 shrink-0 lg:h-3 lg:w-3" />
                <div className="inline-flex flex-wrap gap-1">
                  <span>
                    +<NumberWithPercentage amount={item.percentage} />
                  </span>
                </div>
              </>
            ) : (
              <>
                <ArrowDownIcon className="mr-0.5 h-2.5 w-2.5 shrink-0 lg:h-3 lg:w-3" />
                <div className="inline-flex flex-wrap gap-1">
                  <span>
                    <NumberWithPercentage amount={item.percentage} />
                  </span>
                </div>
              </>
            )}
          </div>
        ) : (
          <span className="leading-10">{EMPTY_DEFAULT}</span>
        )}
      </SkeletonLoader>
    </div>
  );
};
