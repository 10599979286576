// src/FetchContext.js
import React, { ReactNode } from 'react';

import { AxiosInstance } from 'axios';

import { AxiosContext } from './AxiosContext';

export const AxiosProvider = ({
  instance,
  children,
}: {
  children: ReactNode;
  instance: AxiosInstance;
}) => {
  return <AxiosContext.Provider value={{ instance }}>{children}</AxiosContext.Provider>;
};
