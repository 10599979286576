import * as Types from '~anyx/shared/graphql/base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type DocumentDetailQueryVariables = Types.Exact<{
  documentId: Types.Scalars['Long']['input'];
}>;


export type DocumentDetailQuery = { readonly document: { readonly __typename?: 'DocumentPayload', readonly id: string, readonly status: Types.DocumentStatus, readonly url?: string | null } };


export const DocumentDetailDocument = gql`
    query DocumentDetail($documentId: Long!) {
  document(documentId: $documentId) {
    id
    status
    url
  }
}
    `;

/**
 * __useDocumentDetailQuery__
 *
 * To run a query within a React component, call `useDocumentDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useDocumentDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDocumentDetailQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *   },
 * });
 */
export function useDocumentDetailQuery(baseOptions: Apollo.QueryHookOptions<DocumentDetailQuery, DocumentDetailQueryVariables> & ({ variables: DocumentDetailQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DocumentDetailQuery, DocumentDetailQueryVariables>(DocumentDetailDocument, options);
      }
export function useDocumentDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DocumentDetailQuery, DocumentDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DocumentDetailQuery, DocumentDetailQueryVariables>(DocumentDetailDocument, options);
        }
export function useDocumentDetailSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DocumentDetailQuery, DocumentDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DocumentDetailQuery, DocumentDetailQueryVariables>(DocumentDetailDocument, options);
        }
export type DocumentDetailQueryHookResult = ReturnType<typeof useDocumentDetailQuery>;
export type DocumentDetailLazyQueryHookResult = ReturnType<typeof useDocumentDetailLazyQuery>;
export type DocumentDetailSuspenseQueryHookResult = ReturnType<typeof useDocumentDetailSuspenseQuery>;
export type DocumentDetailQueryResult = Apollo.QueryResult<DocumentDetailQuery, DocumentDetailQueryVariables>;
export function refetchDocumentDetailQuery(variables: DocumentDetailQueryVariables) {
      return { query: DocumentDetailDocument, variables: variables }
    }