import { SVGProps } from 'react';

import { AnyUIProviderProps } from '@any-ui-react/core';

import preset from '~anyx/root/tailwind';
import { AnyXIcon } from '~anyx/shared/icons';
import { ColorUtils } from '~anyx/shared/utils';

import { AnyXCSSSpinner } from '../components';

import { ThemeOtherProps } from './types';

export const ANYX_THEME: Omit<AnyUIProviderProps, 'children'> = {
  primaryColor: 'blue',
  theme: {
    components: {
      NavLink: {
        defaultProps: (theme) => {
          return {
            color: theme.colors.blue?.[6],
            styles: {
              icon: {
                color: theme.colors.blue?.[6],
              },
            },
          };
        },
      },
      Skeleton: {
        variants: {
          dark: () => ({
            root: {
              '&::before': {
                backgroundColor: `${ColorUtils.replaceAlpha(
                  preset.theme.extend.colors.gray['7']
                )} !important`,
              },
              '&::after': {
                backgroundColor: `${ColorUtils.replaceAlpha(
                  preset.theme.extend.colors.gray['5']
                )} !important`,
              },
            },
          }),
          default: () => ({
            root: {
              '&::before': {
                backgroundColor: `${ColorUtils.replaceAlpha(
                  preset.theme.extend.colors.gray['1']
                )} !important`,
              },
              '&::after': {
                backgroundColor: `${ColorUtils.replaceAlpha(
                  preset.theme.extend.colors.gray['0']
                )} !important`,
              },
            },
          }),
        },
      },
    },
  },
  other: {
    secondaryColor: 'blue',
    logo: (props: SVGProps<SVGSVGElement>) => <AnyXIcon {...props} />,
    spinner: () => <AnyXCSSSpinner />,
  } satisfies ThemeOtherProps,
};
