import { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';

import 'country-flag-icons/3x2/flags.css';
import { TextInputProps, TextInput, Select } from '@any-ui-react/core';
import {
  CountryCode,
  formatIncompletePhoneNumber,
  getCountries,
  isSupportedCountry,
} from 'libphonenumber-js';

import { LanguageUtils, CountriesEnum } from '~anyx/shared/utils';

import { formatPhone, getIsd } from './InputPhone.utils';
import { InputPhoneItem } from './InputPhoneItem';

export type PhoneDataChange = {
  countryCode?: CountryCode;
  isd?: string;
  number: string;
};

export type PhoneData = {
  countryCode?: string;
  isd?: string | null;
  number?: string | null;
};

export type InputPhoneProps = Omit<TextInputProps, 'value' | 'onChange' | 'defaultValue'> & {
  language?: string;
  // override
  defaultValue?: PhoneData | null;
  value?: PhoneData | null;
  onChange?: (data: PhoneDataChange) => void;
  exclude?: CountryCode[];
};

export const InputPhone = forwardRef<HTMLInputElement, InputPhoneProps>(
  (
    {
      language = LanguageUtils.getTwoLettersCode(LanguageUtils.getCurrentLanguage()),
      disabled,
      error,
      value,
      defaultValue,
      onChange,
      exclude = [],
      ...rest
    }: InputPhoneProps,
    ref
  ) => {
    const [state, setState] = useState<PhoneData>(formatPhone(value || defaultValue));

    const handleReformating = useCallback(() => {
      setState(formatPhone(state));
    }, [state]);

    useEffect(() => {
      setState(formatPhone(value || defaultValue));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value?.countryCode, defaultValue?.countryCode]);

    useEffect(() => {
      onChange?.(state as PhoneDataChange);
    }, [onChange, state]);

    const countries = useMemo(() => {
      return getCountries()
        .filter((country) => !exclude.includes(country))
        .map((country) => ({
          value: country,
          isd: getIsd(country),
          language,
          country,
          label: `(${getIsd(country)})`,
        }));
    }, [exclude, language]);

    return (
      <TextInput
        {...rest}
        error={error}
        classNames={{
          icon: 'w-auto pointer-events-auto pl-0',
          ...rest.classNames,
        }}
        onBlur={handleReformating}
        disabled={disabled || !state?.isd}
        value={state?.number || ''}
        iconWidth={140}
        ref={ref}
        onChange={(e) => {
          setState({
            countryCode: state?.countryCode,
            isd: state?.isd,
            number: isSupportedCountry(state?.countryCode || '')
              ? formatIncompletePhoneNumber(e.target.value || '', state?.countryCode as CountryCode)
              : e.target.value,
          });
        }}
        icon={
          <Select
            withinPortal
            searchable
            disabled={disabled}
            error={!!error}
            itemComponent={InputPhoneItem}
            data={countries}
            icon={
              state.countryCode && (
                <div className="-mr-1 flex">
                  <span className={`flag:${state.countryCode}`} />
                </div>
              )
            }
            onChange={(e: CountriesEnum) => {
              const newValue = formatPhone({
                countryCode: e,
                isd: e,
                number: isSupportedCountry(e)
                  ? formatIncompletePhoneNumber(
                      state.number || '',
                      state?.countryCode as CountryCode
                    )
                  : state.number,
              });
              setState(newValue);
            }}
            className="border-gray-2 border-r"
            classNames={{
              input: 'w-32 h-6 border-none min-h-[1.75rem] shadow-none ml-px pr-6 !pl-8',
            }}
            value={state?.countryCode}
            filter={(value, item) => {
              return `${new Intl.DisplayNames([language || 'en'], { type: 'region' }).of(
                item['country']
              )} ${item['country']} ${item['isd']}`
                .toLowerCase()
                .includes(value);
            }}
          />
        }
      />
    );
  }
);
