import { useCallback, useMemo } from 'react';

import { useSessionStorage } from '@mantine/hooks';

import { useWorkspaceCtx } from '~anyx/common/workspace';
import { SETTING_GUIDE_STORAGE_KEY } from '~anyx/shared/utils';

interface StorageSettingGuideProps {
  storeIds?: string[];
  visitedStoreIds: string[];
}

export const useSessionSettingGuide = () => {
  const { workspace: { storeId = '' } = {} } = useWorkspaceCtx();

  const [sessionSkipSettingGuide, setSessionSkipSettingGuide] =
    useSessionStorage<StorageSettingGuideProps>(
      {
        getInitialValueInEffect: false,
        key: SETTING_GUIDE_STORAGE_KEY,
        defaultValue: {
          visitedStoreIds: [],
          storeIds: [],
        },
      },
      {
        syncAcrossTabs: false,
      }
    );

  const isHiddenSettingGuide = useMemo(() => {
    return sessionSkipSettingGuide?.storeIds?.includes(storeId);
  }, [storeId, sessionSkipSettingGuide?.storeIds]);

  const hideSettingGuide = useCallback(() => {
    setSessionSkipSettingGuide((prevState) => ({
      ...prevState,
      storeIds: storeId
        ? [...new Set([...(prevState?.storeIds || []), storeId])]
        : [...new Set(prevState?.storeIds || [])],
    }));
  }, [storeId, setSessionSkipSettingGuide]);

  const showSettingGuide = () => {
    setSessionSkipSettingGuide((prevState) => ({
      ...prevState,
      storeIds: (sessionSkipSettingGuide?.storeIds || [])?.filter((sId) => sId !== storeId),
    }));
  };

  const setStoreVisitedIds = useCallback(
    () =>
      setSessionSkipSettingGuide((prevState) => ({
        ...prevState,
        visitedStoreIds: storeId
          ? [...new Set([...(prevState?.visitedStoreIds || []), storeId])]
          : [...new Set(prevState?.visitedStoreIds || [])],
      })),
    [storeId, setSessionSkipSettingGuide]
  );

  const isStoreVisited = useMemo(() => {
    return sessionSkipSettingGuide?.visitedStoreIds?.includes(storeId);
  }, [sessionSkipSettingGuide?.visitedStoreIds, storeId]);

  return {
    isHiddenSettingGuide,
    showSettingGuide,
    hideSettingGuide,
    setStoreVisitedIds,
    isStoreVisited,
  };
};
