import loadable from '@loadable/component';
export const ActionFlagIcon = loadable(() => import('./ActionFlag'));
export const AddressBookIcon = loadable(() => import('./AddressBook'));
export const AddressCardIcon = loadable(() => import('./AddressCard'));
export const AiChatIcon = loadable(() => import('./AiChat'));
export const AlertRoundedIcon = loadable(() => import('./AlertRounded'));
export const AlertIcon = loadable(() => import('./Alert'));
export const AmazonSellerCentralFaIcon = loadable(() => import('./AmazonSellerCentralFa'));
export const AnchantoFaIcon = loadable(() => import('./AnchantoFa'));
export const AnchantoLogoIcon = loadable(() => import('./AnchantoLogo'));
export const AnyTagIcon = loadable(() => import('./AnyTag'));
export const AnyXLogoIcon = loadable(() => import('./AnyXLogo'));
export const AnyXIcon = loadable(() => import('./AnyX'));
export const AnychatFaIcon = loadable(() => import('./AnychatFa'));
export const AnylogiFaIcon = loadable(() => import('./AnylogiFa'));
export const AppIcon = loadable(() => import('./App'));
export const ArrowDownIcon = loadable(() => import('./ArrowDown'));
export const ArrowRightIcon = loadable(() => import('./ArrowRight'));
export const ArrowUpCircleIcon = loadable(() => import('./ArrowUpCircle'));
export const ArrowUpIcon = loadable(() => import('./ArrowUp'));
export const AuPayFaIcon = loadable(() => import('./AuPayFa'));
export const AvatarIcon = loadable(() => import('./Avatar'));
export const BlocksIcon = loadable(() => import('./Blocks'));
export const BottlesIcon = loadable(() => import('./Bottles'));
export const BoxIcon = loadable(() => import('./Box'));
export const BubbleInstagramIcon = loadable(() => import('./BubbleInstagram'));
export const BuildingIcon = loadable(() => import('./Building'));
export const BulbIcon = loadable(() => import('./Bulb'));
export const BulletListIcon = loadable(() => import('./BulletList'));
export const ButtonTypeIcon = loadable(() => import('./ButtonType'));
export const CalendarIcon = loadable(() => import('./Calendar'));
export const CampaignIcon = loadable(() => import('./Campaign'));
export const CartIcon = loadable(() => import('./Cart'));
export const ChartBarIcon = loadable(() => import('./ChartBar'));
export const ChartLineIcon = loadable(() => import('./ChartLine'));
export const ChartPieIcon = loadable(() => import('./ChartPie'));
export const ChatCheckIcon = loadable(() => import('./ChatCheck'));
export const ChatErrorIcon = loadable(() => import('./ChatError'));
export const ChatGptIcon = loadable(() => import('./ChatGpt'));
export const ChatIcon = loadable(() => import('./Chat'));
export const CheckCircleIcon = loadable(() => import('./CheckCircle'));
export const CheckIcon = loadable(() => import('./Check'));
export const ChevronDownIcon = loadable(() => import('./ChevronDown'));
export const ChevronLeftIcon = loadable(() => import('./ChevronLeft'));
export const ChevronRightIcon = loadable(() => import('./ChevronRight'));
export const ClearChatIcon = loadable(() => import('./ClearChat'));
export const ClearIcon = loadable(() => import('./Clear'));
export const ClipIcon = loadable(() => import('./Clip'));
export const ClipboardIcon = loadable(() => import('./Clipboard'));
export const ClockIcon = loadable(() => import('./Clock'));
export const CloseIcon = loadable(() => import('./Close'));
export const CompilationIcon = loadable(() => import('./Compilation'));
export const ConditionalSplitIcon = loadable(() => import('./ConditionalSplit'));
export const ConfirmWarningIcon = loadable(() => import('./ConfirmWarning'));
export const ConnectFirstBrandIcon = loadable(() => import('./ConnectFirstBrand'));
export const ConnectIntegrationIcon = loadable(() => import('./ConnectIntegration'));
export const CopyIcon = loadable(() => import('./Copy'));
export const CreateChatIcon = loadable(() => import('./CreateChat'));
export const CrmIcon = loadable(() => import('./Crm'));
export const CrossIcon = loadable(() => import('./Cross'));
export const CursorIcon = loadable(() => import('./Cursor'));
export const DhlFaIcon = loadable(() => import('./DhlFa'));
export const DoorHangerIcon = loadable(() => import('./DoorHanger'));
export const DotIcon = loadable(() => import('./Dot'));
export const DotsHorizontalIcon = loadable(() => import('./DotsHorizontal'));
export const DotsVerticalIcon = loadable(() => import('./DotsVertical'));
export const DownloadIcon = loadable(() => import('./Download'));
export const DragIcon = loadable(() => import('./Drag'));
export const DuplicateIcon = loadable(() => import('./Duplicate'));
export const EarthIcon = loadable(() => import('./Earth'));
export const EditIcon = loadable(() => import('./Edit'));
export const EmailSentIcon = loadable(() => import('./EmailSent'));
export const EmojiIcon = loadable(() => import('./Emoji'));
export const EmptyConnectionIcon = loadable(() => import('./EmptyConnection'));
export const EqualizerIcon = loadable(() => import('./Equalizer'));
export const ExclamationIcon = loadable(() => import('./Exclamation'));
export const ExternalIcon = loadable(() => import('./External'));
export const EyeIcon = loadable(() => import('./Eye'));
export const FacebookFaWhiteIcon = loadable(() => import('./FacebookFaWhite'));
export const FacebookFaIcon = loadable(() => import('./FacebookFa'));
export const FacebookShopFaIcon = loadable(() => import('./FacebookShopFa'));
export const FedexFaIcon = loadable(() => import('./FedexFa'));
export const FedexLogoIcon = loadable(() => import('./FedexLogo'));
export const FileIcon = loadable(() => import('./File'));
export const FilterListIcon = loadable(() => import('./FilterList'));
export const GearClockIcon = loadable(() => import('./GearClock'));
export const GearIcon = loadable(() => import('./Gear'));
export const GiftIcon = loadable(() => import('./Gift'));
export const GitIcon = loadable(() => import('./Git'));
export const GoogleAdsFaIcon = loadable(() => import('./GoogleAdsFa'));
export const GoogleAnalyticsFaIcon = loadable(() => import('./GoogleAnalyticsFa'));
export const HandleIcon = loadable(() => import('./Handle'));
export const HatIcon = loadable(() => import('./Hat'));
export const ImagePlaceholderIcon = loadable(() => import('./ImagePlaceholder'));
export const ImageTypeIcon = loadable(() => import('./ImageType'));
export const ImageVideoTypeIcon = loadable(() => import('./ImageVideoType'));
export const InboxIcon = loadable(() => import('./Inbox'));
export const InformationIcon = loadable(() => import('./Information'));
export const InstagramCircleFaIcon = loadable(() => import('./InstagramCircleFa'));
export const InstagramFaIcon = loadable(() => import('./InstagramFa'));
export const InstagramLogoIcon = loadable(() => import('./InstagramLogo'));
export const InstagramUnfilledIcon = loadable(() => import('./InstagramUnfilled'));
export const IntegrationIcon = loadable(() => import('./Integration'));
export const InventoryIcon = loadable(() => import('./Inventory'));
export const JapanPostFaIcon = loadable(() => import('./JapanPostFa'));
export const JapanPostLogoIcon = loadable(() => import('./JapanPostLogo'));
export const JubelioFaIcon = loadable(() => import('./JubelioFa'));
export const JubelioLogoIcon = loadable(() => import('./JubelioLogo'));
export const KeyboardIcon = loadable(() => import('./Keyboard'));
export const LazadaCircleFaIcon = loadable(() => import('./LazadaCircleFa'));
export const LazadaFaIcon = loadable(() => import('./LazadaFa'));
export const LeftTriangleIcon = loadable(() => import('./LeftTriangle'));
export const LikeIcon = loadable(() => import('./Like'));
export const LineCircleFaIcon = loadable(() => import('./LineCircleFa'));
export const LineFaIcon = loadable(() => import('./LineFa'));
export const LineLogoIcon = loadable(() => import('./LineLogo'));
export const LinkIcon = loadable(() => import('./Link'));
export const ListingIcon = loadable(() => import('./Listing'));
export const LoaderIcon = loadable(() => import('./Loader'));
export const LogOutIcon = loadable(() => import('./LogOut'));
export const LogoAmazonSellerCentralIcon = loadable(() => import('./LogoAmazonSellerCentral'));
export const LogoAnychatIcon = loadable(() => import('./LogoAnychat'));
export const LogoAnydigitalIcon = loadable(() => import('./LogoAnydigital'));
export const LogoAnyfactoryIcon = loadable(() => import('./LogoAnyfactory'));
export const LogoAnylogiIcon = loadable(() => import('./LogoAnylogi'));
export const LogoAnymanagerIcon = loadable(() => import('./LogoAnymanager'));
export const LogoAnyshopIcon = loadable(() => import('./LogoAnyshop'));
export const LogoAnytagIcon = loadable(() => import('./LogoAnytag'));
export const LogoAuPayIcon = loadable(() => import('./LogoAuPay'));
export const LogoDhlIcon = loadable(() => import('./LogoDhl'));
export const LogoFacebookAdsIcon = loadable(() => import('./LogoFacebookAds'));
export const LogoFacebookShopIcon = loadable(() => import('./LogoFacebookShop'));
export const LogoGoogleAdsIcon = loadable(() => import('./LogoGoogleAds'));
export const LogoGoogleAnalytics4Icon = loadable(() => import('./LogoGoogleAnalytics4'));
export const LogoLazadaIcon = loadable(() => import('./LogoLazada'));
export const LogoLogilessIcon = loadable(() => import('./LogoLogiless'));
export const LogoMagaseekIcon = loadable(() => import('./LogoMagaseek'));
export const LogoMakeshopIcon = loadable(() => import('./LogoMakeshop'));
export const LogoMomoIcon = loadable(() => import('./LogoMomo'));
export const LogoPaypayIcon = loadable(() => import('./LogoPaypay'));
export const LogoQoo10Icon = loadable(() => import('./LogoQoo10'));
export const LogoRakutenFashionIcon = loadable(() => import('./LogoRakutenFashion'));
export const LogoRakutenIcon = loadable(() => import('./LogoRakuten'));
export const LogoShopeeIcon = loadable(() => import('./LogoShopee'));
export const LogoShopifyIcon = loadable(() => import('./LogoShopify'));
export const LogoShoplistIcon = loadable(() => import('./LogoShoplist'));
export const LogoStoresIcon = loadable(() => import('./LogoStores'));
export const LogoSuperdeleveryIcon = loadable(() => import('./LogoSuperdelevery'));
export const LogoTikTokShopIcon = loadable(() => import('./LogoTikTokShop'));
export const LogoTokopediaIcon = loadable(() => import('./LogoTokopedia'));
export const LogoYahooAdsIcon = loadable(() => import('./LogoYahooAds'));
export const LogoYahooIcon = loadable(() => import('./LogoYahoo'));
export const LogoZozotownIcon = loadable(() => import('./LogoZozotown'));
export const MagaseekFaIcon = loadable(() => import('./MagaseekFa'));
export const MakeshopFaIcon = loadable(() => import('./MakeshopFa'));
export const MarkerIcon = loadable(() => import('./Marker'));
export const MenuIcon = loadable(() => import('./Menu'));
export const MessengerCircleFaIcon = loadable(() => import('./MessengerCircleFa'));
export const MessengerFaIcon = loadable(() => import('./MessengerFa'));
export const MessengerLogoIcon = loadable(() => import('./MessengerLogo'));
export const MomoFaIcon = loadable(() => import('./MomoFa'));
export const NoImgIcon = loadable(() => import('./NoImg'));
export const OrderIcon = loadable(() => import('./Order'));
export const PaperclipIcon = loadable(() => import('./Paperclip'));
export const PaypayFaIcon = loadable(() => import('./PaypayFa'));
export const PenIcon = loadable(() => import('./Pen'));
export const PencilIcon = loadable(() => import('./Pencil'));
export const PlainTextIcon = loadable(() => import('./PlainText'));
export const PlatformCartIcon = loadable(() => import('./PlatformCart'));
export const PlayIcon = loadable(() => import('./Play'));
export const PlusIcon = loadable(() => import('./Plus'));
export const PointerDownIcon = loadable(() => import('./PointerDown'));
export const PoweredByGoogleIcon = loadable(() => import('./PoweredByGoogle'));
export const PrinterIcon = loadable(() => import('./Printer'));
export const ProductIcon = loadable(() => import('./Product'));
export const PuzzleIcon = loadable(() => import('./Puzzle'));
export const Qoo10FaIcon = loadable(() => import('./Qoo10Fa'));
export const QuestionCircleIcon = loadable(() => import('./QuestionCircle'));
export const QuotaUsedIcon = loadable(() => import('./QuotaUsed'));
export const RakutenFaIcon = loadable(() => import('./RakutenFa'));
export const RakutenFashionFaIcon = loadable(() => import('./RakutenFashionFa'));
export const RefreshIcon = loadable(() => import('./Refresh'));
export const RemoveIcon = loadable(() => import('./Remove'));
export const ReturnIcon = loadable(() => import('./Return'));
export const RichMessageIcon = loadable(() => import('./RichMessage'));
export const ScheduleIcon = loadable(() => import('./Schedule'));
export const SearchIcon = loadable(() => import('./Search'));
export const SendIcon = loadable(() => import('./Send'));
export const SendingMailIcon = loadable(() => import('./SendingMail'));
export const SettingIcon = loadable(() => import('./Setting'));
export const ShopeeCircleFaIcon = loadable(() => import('./ShopeeCircleFa'));
export const ShopeeFaIcon = loadable(() => import('./ShopeeFa'));
export const ShopifyFaIcon = loadable(() => import('./ShopifyFa'));
export const ShopifyIcon = loadable(() => import('./Shopify'));
export const ShoplistFaIcon = loadable(() => import('./ShoplistFa'));
export const SidebarIcon = loadable(() => import('./Sidebar'));
export const SignOutIcon = loadable(() => import('./SignOut'));
export const SketchIcon = loadable(() => import('./Sketch'));
export const SmileChatIcon = loadable(() => import('./SmileChat'));
export const SmileIcon = loadable(() => import('./Smile'));
export const SolidIcon = loadable(() => import('./Solid'));
export const SortDownIcon = loadable(() => import('./SortDown'));
export const SortUpIcon = loadable(() => import('./SortUp'));
export const StoreIcon = loadable(() => import('./Store'));
export const StoresFaIcon = loadable(() => import('./StoresFa'));
export const SuccessIcon = loadable(() => import('./Success'));
export const SuperdeleveryFaIcon = loadable(() => import('./SuperdeleveryFa'));
export const SupplierIcon = loadable(() => import('./Supplier'));
export const SweaterIcon = loadable(() => import('./Sweater'));
export const TagThinIcon = loadable(() => import('./TagThin'));
export const TagIcon = loadable(() => import('./Tag'));
export const TargetIcon = loadable(() => import('./Target'));
export const TerminalIcon = loadable(() => import('./Terminal'));
export const ThreeCirclesIcon = loadable(() => import('./ThreeCircles'));
export const ThunderIcon = loadable(() => import('./Thunder'));
export const TikTokFaIcon = loadable(() => import('./TikTokFa'));
export const TikTokIcon = loadable(() => import('./TikTok'));
export const TimeIcon = loadable(() => import('./Time'));
export const TimerIcon = loadable(() => import('./Timer'));
export const TokopediaFaIcon = loadable(() => import('./TokopediaFa'));
export const TrashIcon = loadable(() => import('./Trash'));
export const UpsFaIcon = loadable(() => import('./UpsFa'));
export const UpsLogoIcon = loadable(() => import('./UpsLogo'));
export const UserOutlineIcon = loadable(() => import('./UserOutline'));
export const UserIcon = loadable(() => import('./User'));
export const UserSettingsIcon = loadable(() => import('./UserSettings'));
export const VolumeFullIcon = loadable(() => import('./VolumeFull'));
export const WarehouseIcon = loadable(() => import('./Warehouse'));
export const WarningIcon = loadable(() => import('./Warning'));
export const WhatsappCircleFaIcon = loadable(() => import('./WhatsappCircleFa'));
export const WhatsappFaIcon = loadable(() => import('./WhatsappFa'));
export const WhatsappLogoIcon = loadable(() => import('./WhatsappLogo'));
export const WorkflowIcon = loadable(() => import('./Workflow'));
export const YahooFaIcon = loadable(() => import('./YahooFa'));
export const ZozotownFaIcon = loadable(() => import('./ZozotownFa'));
