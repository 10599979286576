import * as Types from '~anyx/shared/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SettingGuideSaleChannelQueryVariables = Types.Exact<{
  mdStoreId: Types.Scalars['Long']['input'];
}>;


export type SettingGuideSaleChannelQuery = { readonly MdStore: { readonly __typename?: 'MdStore', readonly id: string, readonly channels: ReadonlyArray<{ readonly __typename?: 'SaleChannel', readonly id: string, readonly type: Types.MarketplaceSaleChannelType, readonly costIndex?: { readonly __typename?: 'SaleChannelCostIndexQueryPayload', readonly saleChannelId: string } | null }> } };


export const SettingGuideSaleChannelDocument = gql`
    query SettingGuideSaleChannel($mdStoreId: Long!) {
  MdStore(id: $mdStoreId) {
    id
    channels {
      id
      type
      costIndex {
        saleChannelId
      }
    }
  }
}
    `;

/**
 * __useSettingGuideSaleChannelQuery__
 *
 * To run a query within a React component, call `useSettingGuideSaleChannelQuery` and pass it any options that fit your needs.
 * When your component renders, `useSettingGuideSaleChannelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSettingGuideSaleChannelQuery({
 *   variables: {
 *      mdStoreId: // value for 'mdStoreId'
 *   },
 * });
 */
export function useSettingGuideSaleChannelQuery(baseOptions: Apollo.QueryHookOptions<SettingGuideSaleChannelQuery, SettingGuideSaleChannelQueryVariables> & ({ variables: SettingGuideSaleChannelQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SettingGuideSaleChannelQuery, SettingGuideSaleChannelQueryVariables>(SettingGuideSaleChannelDocument, options);
      }
export function useSettingGuideSaleChannelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SettingGuideSaleChannelQuery, SettingGuideSaleChannelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SettingGuideSaleChannelQuery, SettingGuideSaleChannelQueryVariables>(SettingGuideSaleChannelDocument, options);
        }
export function useSettingGuideSaleChannelSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SettingGuideSaleChannelQuery, SettingGuideSaleChannelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SettingGuideSaleChannelQuery, SettingGuideSaleChannelQueryVariables>(SettingGuideSaleChannelDocument, options);
        }
export type SettingGuideSaleChannelQueryHookResult = ReturnType<typeof useSettingGuideSaleChannelQuery>;
export type SettingGuideSaleChannelLazyQueryHookResult = ReturnType<typeof useSettingGuideSaleChannelLazyQuery>;
export type SettingGuideSaleChannelSuspenseQueryHookResult = ReturnType<typeof useSettingGuideSaleChannelSuspenseQuery>;
export type SettingGuideSaleChannelQueryResult = Apollo.QueryResult<SettingGuideSaleChannelQuery, SettingGuideSaleChannelQueryVariables>;
export function refetchSettingGuideSaleChannelQuery(variables: SettingGuideSaleChannelQueryVariables) {
      return { query: SettingGuideSaleChannelDocument, variables: variables }
    }