import * as Types from '~anyx/shared/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type HomeReportTableQueryVariables = Types.Exact<{
  filter: Types.SaleChannelReportTableFilterInput;
}>;


export type HomeReportTableQuery = { readonly saleChannelReportTable: { readonly __typename?: 'SaleChannelReportPayload', readonly currency: Types.ReportSupportedCurrency, readonly totalItems: number, readonly list: ReadonlyArray<{ readonly __typename?: 'SaleChannelReportTableData', readonly advertisingCost?: string | null, readonly advertisingRevenue?: string | null, readonly avgOrderValues: string, readonly commissionFee?: string | null, readonly cvr?: string | null, readonly discountRate: string, readonly discounts: string, readonly grossProfit: string, readonly grossProfitRate?: string | null, readonly grossSales: string, readonly netSales: string, readonly totalSales: string, readonly cancelAndOrRefund: string, readonly logisticsCost?: string | null, readonly marginalProfit: string, readonly marginalProfitRate?: string | null, readonly merchandiseCost?: string | null, readonly metric: string, readonly orders: string, readonly otherCost: string, readonly roas?: string | null, readonly sessions?: string | null, readonly shippingFee: string }> } };


export const HomeReportTableDocument = gql`
    query HomeReportTable($filter: SaleChannelReportTableFilterInput!) {
  saleChannelReportTable(filter: $filter) {
    currency
    list {
      advertisingCost
      advertisingRevenue
      avgOrderValues
      commissionFee
      cvr
      discountRate
      discounts
      grossProfit
      grossProfitRate
      grossSales
      netSales
      totalSales
      cancelAndOrRefund
      logisticsCost
      marginalProfit
      marginalProfitRate
      merchandiseCost
      metric
      orders
      otherCost
      roas
      sessions
      shippingFee
    }
    totalItems
  }
}
    `;

/**
 * __useHomeReportTableQuery__
 *
 * To run a query within a React component, call `useHomeReportTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomeReportTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomeReportTableQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useHomeReportTableQuery(baseOptions: Apollo.QueryHookOptions<HomeReportTableQuery, HomeReportTableQueryVariables> & ({ variables: HomeReportTableQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<HomeReportTableQuery, HomeReportTableQueryVariables>(HomeReportTableDocument, options);
      }
export function useHomeReportTableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<HomeReportTableQuery, HomeReportTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<HomeReportTableQuery, HomeReportTableQueryVariables>(HomeReportTableDocument, options);
        }
export function useHomeReportTableSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<HomeReportTableQuery, HomeReportTableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<HomeReportTableQuery, HomeReportTableQueryVariables>(HomeReportTableDocument, options);
        }
export type HomeReportTableQueryHookResult = ReturnType<typeof useHomeReportTableQuery>;
export type HomeReportTableLazyQueryHookResult = ReturnType<typeof useHomeReportTableLazyQuery>;
export type HomeReportTableSuspenseQueryHookResult = ReturnType<typeof useHomeReportTableSuspenseQuery>;
export type HomeReportTableQueryResult = Apollo.QueryResult<HomeReportTableQuery, HomeReportTableQueryVariables>;
export function refetchHomeReportTableQuery(variables: HomeReportTableQueryVariables) {
      return { query: HomeReportTableDocument, variables: variables }
    }