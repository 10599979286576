import { ReactNode } from 'react';

import { Skeleton, SkeletonProps } from '@mantine/core';

export type SkeletonLoaderProps = {
  loading?: boolean;
  children?: ReactNode;
} & SkeletonProps;

export const SkeletonLoader = ({
  loading,
  children,
  variant = 'default',
  height = 14,
  ...rest
}: SkeletonLoaderProps) => {
  return (
    <>
      {loading ? (
        <Skeleton visible={loading} variant={variant} height={height} {...rest} />
      ) : (
        children
      )}
    </>
  );
};
