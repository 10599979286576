import { ReactNode, forwardRef } from 'react';

import { InputPostalCodeFooter } from './InputPostalCodeFooter';

interface ItemProps extends React.ComponentPropsWithoutRef<'div'> {
  footer: boolean;
  hidden: boolean;
  label: ReactNode;
}

export const InputPostalCodeItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ footer, label, hidden, ...others }: ItemProps, ref) => {
    if (hidden) return null;

    return !footer ? (
      <div ref={ref} {...others}>
        <span className="truncate"> {label}</span>
      </div>
    ) : (
      <InputPostalCodeFooter />
    );
  }
);
