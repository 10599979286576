import * as Types from '~anyx/shared/graphql/base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type ReportDocumentDetailQueryVariables = Types.Exact<{
  id: Types.Scalars['Long']['input'];
}>;


export type ReportDocumentDetailQuery = { readonly reportDocument: { readonly __typename?: 'ReportDocumentPayload', readonly id: string, readonly status: Types.ReportDocumentStatus, readonly url?: string | null } };


export const ReportDocumentDetailDocument = gql`
    query reportDocumentDetail($id: Long!) {
  reportDocument(id: $id) {
    id
    status
    url
  }
}
    `;

/**
 * __useReportDocumentDetailQuery__
 *
 * To run a query within a React component, call `useReportDocumentDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportDocumentDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportDocumentDetailQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReportDocumentDetailQuery(baseOptions: Apollo.QueryHookOptions<ReportDocumentDetailQuery, ReportDocumentDetailQueryVariables> & ({ variables: ReportDocumentDetailQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportDocumentDetailQuery, ReportDocumentDetailQueryVariables>(ReportDocumentDetailDocument, options);
      }
export function useReportDocumentDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportDocumentDetailQuery, ReportDocumentDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportDocumentDetailQuery, ReportDocumentDetailQueryVariables>(ReportDocumentDetailDocument, options);
        }
export function useReportDocumentDetailSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ReportDocumentDetailQuery, ReportDocumentDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ReportDocumentDetailQuery, ReportDocumentDetailQueryVariables>(ReportDocumentDetailDocument, options);
        }
export type ReportDocumentDetailQueryHookResult = ReturnType<typeof useReportDocumentDetailQuery>;
export type ReportDocumentDetailLazyQueryHookResult = ReturnType<typeof useReportDocumentDetailLazyQuery>;
export type ReportDocumentDetailSuspenseQueryHookResult = ReturnType<typeof useReportDocumentDetailSuspenseQuery>;
export type ReportDocumentDetailQueryResult = Apollo.QueryResult<ReportDocumentDetailQuery, ReportDocumentDetailQueryVariables>;
export function refetchReportDocumentDetailQuery(variables: ReportDocumentDetailQueryVariables) {
      return { query: ReportDocumentDetailDocument, variables: variables }
    }