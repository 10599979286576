import React, { ReactElement, Ref, forwardRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Select, NumberInput, NumberInputProps } from '@any-ui-react/core';
import { useUncontrolled } from '@mantine/hooks';

import { ObjectUtils, CurrencyUtils, CurrenciesEnum } from '~anyx/shared/utils';

export interface InputCurrencyData<T extends string | null> {
  currency: T;
  amount: string;
}

export type InputCurrencyProps<T extends string | null> = Omit<
  NumberInputProps,
  'value' | 'defaultValue' | 'onChange'
> & {
  currencies?: CurrenciesEnum[];
  menuPortalTarget?: HTMLElement;
  // Override
  defaultValue?: InputCurrencyData<T> | null;
  value?: InputCurrencyData<T> | null;
  onChange?: (data: InputCurrencyData<T>) => void;
  min?: number | string;
  max?: number | string;
};

const InputCurrencyFn = <T extends string | null>(
  {
    value,
    defaultValue,
    disabled,
    error,
    min = 0,
    max,
    onChange,
    menuPortalTarget,
    currencies = ObjectUtils.arrayFromEnum<CurrenciesEnum>(CurrenciesEnum),
    ...rest
  }: InputCurrencyProps<T>,
  ref: React.Ref<HTMLInputElement>
) => {
  const { t } = useTranslation();

  const [_value, handleChange] = useUncontrolled<InputCurrencyData<T> | null>({
    value,
    defaultValue,
    finalValue: null,
    onChange,
  });

  const defaultCurrency = useMemo(() => {
    return (
      defaultValue?.currency ||
      (currencies.includes(CurrenciesEnum.JPY) ? CurrenciesEnum.JPY : currencies[0]) ||
      CurrenciesEnum.JPY
    );
  }, [currencies, defaultValue]);
  return (
    <NumberInput
      {...rest}
      ref={ref}
      error={error}
      disabled={disabled}
      type="number"
      placeholder="0"
      value={_value?.amount ? Number(_value?.amount) : ''}
      min={min ? Number(min) : undefined}
      max={min ? Number(max) : undefined}
      precision={CurrencyUtils.getRelevantDecimals(value?.currency as unknown as string)}
      onChange={(amount) => {
        handleChange({
          amount: amount.toString(),
          currency: (value?.currency || defaultCurrency) as T,
        });
      }}
      rightSectionWidth={80}
      rightSection={
        <Select
          searchable
          value={(_value?.currency as T) || defaultCurrency}
          disabled={disabled}
          error={!!error}
          withinPortal
          portalProps={{
            target: menuPortalTarget,
          }}
          className="border-gray-2 border-l"
          classNames={{
            input: 'w-20 h-6 border-none min-h-[1.75rem] shadow-none mr-1 pr-6',
          }}
          data={currencies.map((option) => ({
            label: t('shared.enum.currency', {
              ns: 'shared',
              currency: option,
              compact: true,
            }),
            value: option,
          }))}
          onChange={(currency: T) => {
            if (!currency) return;
            handleChange({
              amount: Number(value?.amount).toFixed(CurrencyUtils.getRelevantDecimals(currency)),
              currency: currency,
            });
          }}
        />
      }
    />
  );
};

export const InputCurrency = forwardRef(InputCurrencyFn) as <T extends string | null>(
  p: InputCurrencyProps<T> & {
    ref?: Ref<HTMLInputElement>;
  }
) => ReactElement;
