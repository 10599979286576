import { useCallback } from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { AutoCompleteResult, InputPostalCode, InputPostalCodeProps } from '~anyx/shared/ui';
import { AddressPart } from '~anyx/shared/utils';

import { useAddressFormContext } from '../context';
import { useProvinceOptions } from '../hooks';

export type PostalCodeFieldProps = Partial<InputPostalCodeProps>;

export const PostalCodeField = ({ ...rest }: PostalCodeFieldProps) => {
  const { t } = useTranslation();
  const { selectedCountry, language, mappings, useRegionId } = useAddressFormContext();
  const { provinceOptions } = useProvinceOptions({
    useRegionId,
    country: selectedCountry,
    language,
  });
  const { control, setValue, getFieldState } = useFormContext();

  const fieldName = mappings[AddressPart.POSTAL_CODE];

  const onAutocompletion = useCallback(
    (completion: AutoCompleteResult) => {
      const setValueIfMapped = (part: keyof AutoCompleteResult, override?: string | null) => {
        const mapping = mappings[part];

        if (mapping) {
          setValue(mapping, override || completion[part] || null);
        }
      };

      const province = provinceOptions.find(
        ({ label }) => label === completion[AddressPart.REGION]
      );

      setValueIfMapped(AddressPart.REGION, useRegionId ? province?.value : province?.label);
      setValueIfMapped(AddressPart.CITY);
      setValueIfMapped(AddressPart.POSTAL_CODE);
      setValueIfMapped(AddressPart.ADDRESS_SECOND);
      setValueIfMapped(AddressPart.ADDRESS_FIRST);
    },
    [useRegionId, mappings, provinceOptions, setValue]
  );

  if (!fieldName) {
    return;
  }

  return (
    <Controller
      control={control}
      name={fieldName}
      render={({ field: { ...props } }) => (
        <InputPostalCode
          {...props}
          label={t('shared.field.zipcode.name', { ns: 'shared' })}
          placeholder={t('shared.field.zipcode.placeholder', { ns: 'shared' })}
          country={selectedCountry}
          withAutoComplete={true}
          withinPortal={true}
          required
          error={getFieldState(fieldName)?.error?.message?.toString()}
          onAutoComplete={onAutocompletion}
          {...rest}
        />
      )}
    />
  );
};
