import { useState, useEffect, useCallback } from 'react';

import { AxiosRequestConfig, AxiosResponse } from 'axios';

import { useAxiosCtx } from './AxiosContext';

export const useFetchQuery = <T,>(
  url: string,
  {
    variables = {},
    lazy = false,
    config,
  }: {
    variables?: Record<string, unknown>;
    lazy?: boolean;
    config?: AxiosRequestConfig<unknown> | undefined;
  }
): [
  (fetchVariables?: { variables?: Record<string, unknown> }) => Promise<AxiosResponse<T, unknown>>,
  { data: AxiosResponse<T> | null; loading: boolean; error: unknown }
] => {
  const { instance } = useAxiosCtx();
  const [data, setData] = useState<AxiosResponse<T> | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<unknown>(null);

  const fetchData = useCallback(
    async ({ variables: fetchVariables }: { variables?: Record<string, unknown> } = {}) => {
      setLoading(true);
      setError(null);
      const runVariables = fetchVariables || variables;
      try {
        const queryString = new URLSearchParams(runVariables as Record<string, string>).toString();

        const result = await instance.get<T>(
          `${url}${queryString ? `?${queryString}` : ''}`,
          config
        );
        setData(result);
        return result;
      } catch (err) {
        setError(err);
        throw err;
      } finally {
        setLoading(false);
      }
    },
    [config, instance, url, variables]
  );

  useEffect(() => {
    if (lazy) return;

    fetchData();
  }, [fetchData, lazy]);

  return [fetchData, { data, loading, error }];
};
