import { Skeleton } from '@mantine/core';
import classNames from 'classnames';

import { SkeletonLoaderProps } from '../skeleton-loader/SkeletonLoader';

interface SkeletonLoaderWithLabelProps extends SkeletonLoaderProps {
  containerClassName?: string;
}

export const SkeletonLoaderWithLabel = ({
  loading,
  children,
  containerClassName,
  className,
  variant = 'default',
}: SkeletonLoaderWithLabelProps) => {
  return loading ? (
    <div className={classNames('flex flex-col gap-1', containerClassName)}>
      <Skeleton variant={variant} className="h-4 w-32 leading-8" />
      <Skeleton variant={variant} className={className} height={32} />
    </div>
  ) : (
    children
  );
};
