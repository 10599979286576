import { ReactNode } from 'react';

import axios from 'axios';
import Cookies from 'js-cookie';

import { environment } from '~anyx/app-core/env';

import { AxiosProvider as Provider } from '~anyx/shared/utils';

export function AxiosProvider({ children }: { children: ReactNode }) {
  const axiosInstance = axios.create({
    baseURL: environment.api.rest,
  });

  axiosInstance.interceptors.request.use(
    (config) => {
      // Get the authorization token from cookies
      const token = Cookies.get('authorization');

      if (token) {
        // Set the Authorization header for the request
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    },
    (error) => {
      // Handle the error
      return Promise.reject(error);
    }
  );

  return <Provider instance={axiosInstance}>{children}</Provider>;
}
