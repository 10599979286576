import { useTranslation } from 'react-i18next';

import { isValid } from 'date-fns';
import { TooltipProps } from 'recharts';
import { NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';

import { CurrenciesEnum, DateUtils, EMPTY_DEFAULT } from '~anyx/shared/utils';

import { NumberWithCurrency, NumberWithFormat, NumberWithPercentage } from '../../atoms';

import { formatReportDate } from './chart.config';
import { NumberFormat } from './MultiValueTooltip';

export const SingleValueTooltip = ({
  active,
  payload,
  footerContent,
  showTarget,
}: TooltipProps<ValueType, NameType> & {
  footerContent?: React.ReactNode;
  showTarget?: boolean;
}) => {
  const { t } = useTranslation();

  const formatNumber = ({
    numberFormat,
    value,
    currency,
  }: {
    numberFormat: string;
    value: number;
    currency: CurrenciesEnum;
  }) => {
    switch (numberFormat) {
      case NumberFormat.PRICE:
        return (
          <NumberWithCurrency
            amount={value}
            currency={currency}
            currencyClassName="ml-0.5 text-4xs"
          />
        );
      case NumberFormat.PERCENTAGE:
        return <NumberWithPercentage amount={value} />;
      default:
        return value ? <NumberWithFormat amount={+value} /> : EMPTY_DEFAULT;
    }
  };
  if (active && payload) {
    return (
      <div
        className={
          'shadow-tooltip bg-chartTooltip relative -translate-y-[calc(50%+1rem)] translate-x-1 rounded p-2 text-white opacity-95'
        }
      >
        {payload.map((item, i) => {
          if (item.dataKey === 'totalTarget') return null;
          const { key, name, numberFormat, value, currency, totalTarget } = item?.payload || {};

          return (
            <div key={i}>
              <p className="text-4xs font-semibold">
                {isValid(new Date(key)) && typeof key === 'number'
                  ? `${formatReportDate(key)} (${DateUtils.formatDate(
                      key,
                      DateUtils.FORMATS.EEEE
                    )})`
                  : key}
              </p>
              <p className="text-4xs font-semibold">{name}</p>
              <p className="text-sm font-semibold">
                {formatNumber({
                  numberFormat: numberFormat,
                  value: value,
                  currency: currency,
                })}
              </p>
              {showTarget && totalTarget >= 0 && (
                <>
                  <p className="text-4xs font-semibold">
                    {t('report.page.eventCalendar.targetGrossSales', {
                      ns: 'report',
                    })}
                  </p>
                  <p className="text-sm font-semibold">
                    {formatNumber({
                      numberFormat: NumberFormat.PRICE,
                      value: totalTarget,
                      currency: currency,
                    })}
                  </p>
                </>
              )}
            </div>
          );
        })}
        {footerContent}
      </div>
    );
  }

  return null;
};
