import { tv } from 'tailwind-variants';

export const navItemStyles = tv({
  slots: {
    root: 'w-full group flex cursor-pointer items-center rounded px-2 gap-x-2 font-semibold text-xs h-8 hover:bg-gray-0',
    icon: 'h-6 w-6 mr-0',
    title: 'flex-1 first-letter:uppercase text-xs',
    itemIcon: 'h-6 w-6 -mr-2',
  },
  variants: {
    active: {
      true: {},
      false: {
        icon: 'fill-gray-7',
      },
    },
    light: {
      true: {},
    },
  },
  compoundVariants: [
    {
      active: false,
      light: true,
      class: {
        root: 'text-gray-7',
      },
    },
    {
      root: {
        active: false,
        light: false,
        class: {
          root: 'text-gray-9',
        },
      },
    },
  ],
  defaultVariants: {
    active: false,
    light: false,
  },
});
