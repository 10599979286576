import { createContext, useContext } from 'react';

import axios, { AxiosInstance } from 'axios';

type AxiosContextType = {
  instance: AxiosInstance;
};
export const defaultInstance: AxiosContextType = {
  instance: axios.create(),
};

export const AxiosContext = createContext(defaultInstance);

export const useAxiosCtx = () => {
  const { ...instance } = useContext(AxiosContext);
  return instance;
};
