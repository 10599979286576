import { useCallback } from 'react';

import { ApolloError } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { GraphQLFormattedError } from 'graphql';

import { toast } from '~anyx/shared/ui';

import { AlertType } from '../models';
import { GqlErrors } from '../utils';

import { useApiTranslationMapper } from './useApiTranslationMapper';

export const useGraphQLValidationError = (key = 'VALIDATION_ERROR') => {
  const { getTranslationKey, getErrorMessage } = useApiTranslationMapper();

  const getGraphQLValidationError = useCallback(
    (graphQLErrors: readonly GraphQLFormattedError[], path: string) => {
      const validationError = GqlErrors.extractGraphqlError(graphQLErrors, key);

      if (validationError) {
        const extensionsErrors = validationError.extensions?.['errors'];
        const gqlValidationErrors = Array.isArray(extensionsErrors) ? extensionsErrors : [];

        const validationErrors = gqlValidationErrors.map(({ message, metadata }) =>
          getTranslationKey({
            namespace: 'gql',
            type: AlertType.ERROR,
            path,
            message,
            options: metadata,
          })
        );

        return validationErrors;
      }
      return [];
    },
    [getTranslationKey, key]
  );

  // Retrieve validation errors from unknown error, translate and return success or failure with the mapping
  // could be put somewhere else but for now I put here
  function handleValidationErrors(e: unknown, path: string) {
    if (e instanceof ApolloError) {
      const { graphQLErrors } = e;
      const validationErrors = getGraphQLValidationError(graphQLErrors, path);

      if (validationErrors.length > 0) {
        return {
          success: false,
          validationErrors,
        };
      } else {
        graphQLErrors.map((error) =>
          toast.error(
            getTranslationKey({
              namespace: 'gql',
              type: AlertType.ERROR,
              path,
              message: error?.message,
            })
          )
        );

        return {
          success: false,
        };
      }
    } else {
      Sentry.captureException(e);
      toast.error(
        getTranslationKey({
          namespace: 'gql',
          type: AlertType.ERROR,
          path,
          message: getErrorMessage(e),
        })
      );
      return { success: false };
    }
  }

  return { getGraphQLValidationError, handleValidationErrors };
};
