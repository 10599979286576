import { forwardRef, useCallback, useEffect, useState } from 'react';

import { TextInputProps, Select, SelectProps, TextInput } from '@any-ui-react/core';
import i18n from 'i18next';

import {
  AddressPart,
  GoogleLocationServiceUtils,
  ILocationServiceUtils,
  GeoUtils,
} from '~anyx/shared/utils';

import { buildAddress } from './buildAddress';
import { InputPostalCodeItem } from './InputPostalCodeItem';
import { InputPostalCodeNoOptions } from './InputPostalCodeNoOptions';

export interface AutoCompleteResult {
  [AddressPart.POSTAL_CODE]: string;
  [AddressPart.REGION]: string;
  [AddressPart.CITY]: string;
  [AddressPart.ADDRESS_FIRST]: string;
  [AddressPart.ADDRESS_SECOND]: string;
}

interface BaseInputPostalCodeProps {
  country?: string;
  onAutoComplete?: (result: AutoCompleteResult) => void;
  utils?: ILocationServiceUtils;
  withAutoComplete?: boolean;
  value?: string;
  defaultValue?: string;
  onChange?: (e: string) => void;
}

export interface InputPostalCodePropsWithInput
  extends Omit<TextInputProps, 'onChange' | 'value' | 'defaultValue'>,
    BaseInputPostalCodeProps {}

export interface InputPostalCodePropsWithSelect
  extends Omit<SelectProps, 'onChange' | 'value' | 'defaultValue' | 'data'>,
    BaseInputPostalCodeProps {}

export type InputPostalCodeProps = BaseInputPostalCodeProps['withAutoComplete'] extends true
  ? InputPostalCodePropsWithSelect
  : InputPostalCodePropsWithInput;

export const InputPostalCode = forwardRef<
  HTMLInputElement,
  InputPostalCodePropsWithSelect | InputPostalCodePropsWithInput
>(
  (
    {
      value,
      defaultValue,
      onChange,
      onAutoComplete,
      utils = new GoogleLocationServiceUtils(),
      country,
      withAutoComplete = true,
      ...rest
    }: InputPostalCodeProps,
    _ref
  ) => {
    const [predictionResults, setPredictionResults] = useState<
      google.maps.places.AutocompletePrediction[]
    >([]);
    const [language, setLanguage] = useState<string | null>(null);

    const loadPrediction = useCallback(async () => {
      setPredictionResults([]);

      if (!country) return;

      try {
        const [province] = await utils.getProvinceOptions(country);
        if (!province) return;

        const showInEnglish = await GeoUtils.shouldShowInEnglish(country, province.lang);

        setLanguage(showInEnglish ? 'en-US' : i18n.language);
      } catch {
        setPredictionResults([]);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [country]);

    useEffect(() => {
      loadPrediction();
    }, [country, loadPrediction]);

    const handleInput = useCallback(
      async (text: string) => {
        onChange?.(text);
        if (!country || !language) {
          return;
        }

        if (!text.length) {
          setPredictionResults([]);
        }
        const predictions = await utils.fetchPredictions(country, text, language);
        if (predictions) {
          setPredictionResults(predictions);
        }
      },
      [country, language, onChange, utils]
    );

    const selectPlace = useCallback(
      async (placeId: string) => {
        if (!country || !language) {
          return;
        }

        const result = await utils.handleAutoComplete(placeId, language);
        if (result) {
          const completion = buildAddress(country, result);
          onAutoComplete?.(completion);
        }
      },
      [country, language, onAutoComplete, utils]
    );

    return withAutoComplete ? (
      <Select
        {...rest}
        searchable
        value={value}
        defaultValue={defaultValue}
        onChange={selectPlace}
        onSearchChange={handleInput}
        itemComponent={InputPostalCodeItem}
        nothingFound={<InputPostalCodeNoOptions />}
        data={[
          ...predictionResults.map(({ description, place_id: placeId }) => ({
            label: description,
            value: placeId,
          })),
          ...(value ? [{ value, label: value, hidden: true }] : []),
          ...[{ footer: true, value: '', label: '' }],
        ]}
        filter={(value, item) =>
          (!item['hidden'] && item['footer']) || item['label']?.toLowerCase().includes(value)
        }
      />
    ) : (
      <TextInput {...rest} value={value} onChange={(e) => onChange?.(e?.target?.value)} />
    );
  }
);
