import { useMemo } from 'react';

import { useIsAdmin } from '~anyx/common/permission';
import { useWorkspaceCtx } from '~anyx/common/workspace';
import { useFeatureFlag } from '~anyx/external/firebase';
import { useAiChatFeatureFlag } from '~anyx/function-report/hooks';

import { useConnectedReportChannels } from '../../../useConnectedReportChannels';
import { WithWorkspaceLinkProps } from '../../types';
import {
  ANALYTICS_PERMISSION_PROPS,
  CRM_PERMISSION_PROPS,
  DATASOURCE_PERMISSION_PROPS,
  INVENTORY_PERMISSION_PROPS,
  PRODUCT_PERMISSION_PROPS,
  SHIPPING_PERMISSION_PROPS,
  USER_PERMISSION_PROPS,
  USE_ORDER_PERMISSION_PROPS,
  WORKFLOW_PERMISSION_PROPS,
} from '../permissions';
import { PermissionContainer } from '../types';
import { useBuildPermissions } from '../utils';

const WITH_WORKSPACE_PERMISSION: PermissionContainer = {
  canSeeUserList: USER_PERMISSION_PROPS,
  canUseOrder: USE_ORDER_PERMISSION_PROPS,
  canUseAnalytics: ANALYTICS_PERMISSION_PROPS,
  canUseCrm: CRM_PERMISSION_PROPS,
  canUseDatasource: DATASOURCE_PERMISSION_PROPS,
  canUseInventory: INVENTORY_PERMISSION_PROPS,
  canUseProduct: PRODUCT_PERMISSION_PROPS,
  canUseShipping: SHIPPING_PERMISSION_PROPS,
  canUseWorkflow: WORKFLOW_PERMISSION_PROPS,
};

export const usePermissionsWithWorkspace = () => {
  const { workspace } = useWorkspaceCtx();
  const { isAdmin } = useIsAdmin();
  const { reportChannels } = useConnectedReportChannels({
    storeId: workspace?.storeId || '',
  });

  // FEATURE FLAG ------------------------------ //
  const canSeeAi = useAiChatFeatureFlag();
  const rpReportStockValueActiveFlag = useFeatureFlag('rp_ddi_active', { as: 'string' });
  const isReportStockValuePageActive = useMemo(() => {
    const flagValue = JSON.parse(rpReportStockValueActiveFlag?.value || '{}');
    return flagValue?.allowedAccountIds?.includes(workspace?.accountId);
  }, [rpReportStockValueActiveFlag?.value, workspace?.accountId]);

  // ------------------------------ //

  const { getValidatedPermissions } = useBuildPermissions();

  const getPermissionsWithWorkspace = (): WithWorkspaceLinkProps => {
    const { canUseShipping, ...validatedPermissions } =
      getValidatedPermissions(WITH_WORKSPACE_PERMISSION);

    return {
      workspaceAccountId: workspace?.accountId,
      accountId: workspace?.accountId,
      storeId: workspace?.storeId,
      isCrmEnabled: workspace?.crmWorkspace?.isCrmEnabled,
      chats: workspace?.crmWorkspace?.chats || [],
      canUseShipping: canUseShipping,
      canSeeInventoryStockValue: isReportStockValuePageActive,
      canSeeAi,
      channels: reportChannels,
      isAdmin,
      ...validatedPermissions,
    };
  };

  return { getPermissionsWithWorkspace };
};
