import * as Types from '~anyx/shared/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MdStoresSelectQueryVariables = Types.Exact<{
  pageNumber: Types.Scalars['Int']['input'];
  pageSize: Types.Scalars['Int']['input'];
  filter?: Types.InputMaybe<Types.MdStoreFilterInput>;
  sortBy?: Types.InputMaybe<Types.MdStoreSortByInput>;
}>;


export type MdStoresSelectQuery = { readonly MdStores: { readonly __typename?: 'MdStores', readonly length: number, readonly limit: number, readonly start: number, readonly total: number, readonly items: ReadonlyArray<{ readonly __typename?: 'MdStore', readonly id: string, readonly name: string }> } };


export const MdStoresSelectDocument = gql`
    query MdStoresSelect($pageNumber: Int!, $pageSize: Int!, $filter: MdStoreFilterInput, $sortBy: MdStoreSortByInput) {
  MdStores(
    pageNumber: $pageNumber
    pageSize: $pageSize
    filter: $filter
    sortBy: $sortBy
  ) {
    length
    limit
    start
    total
    items {
      id
      name
    }
  }
}
    `;

/**
 * __useMdStoresSelectQuery__
 *
 * To run a query within a React component, call `useMdStoresSelectQuery` and pass it any options that fit your needs.
 * When your component renders, `useMdStoresSelectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMdStoresSelectQuery({
 *   variables: {
 *      pageNumber: // value for 'pageNumber'
 *      pageSize: // value for 'pageSize'
 *      filter: // value for 'filter'
 *      sortBy: // value for 'sortBy'
 *   },
 * });
 */
export function useMdStoresSelectQuery(baseOptions: Apollo.QueryHookOptions<MdStoresSelectQuery, MdStoresSelectQueryVariables> & ({ variables: MdStoresSelectQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MdStoresSelectQuery, MdStoresSelectQueryVariables>(MdStoresSelectDocument, options);
      }
export function useMdStoresSelectLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MdStoresSelectQuery, MdStoresSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MdStoresSelectQuery, MdStoresSelectQueryVariables>(MdStoresSelectDocument, options);
        }
export function useMdStoresSelectSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MdStoresSelectQuery, MdStoresSelectQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MdStoresSelectQuery, MdStoresSelectQueryVariables>(MdStoresSelectDocument, options);
        }
export type MdStoresSelectQueryHookResult = ReturnType<typeof useMdStoresSelectQuery>;
export type MdStoresSelectLazyQueryHookResult = ReturnType<typeof useMdStoresSelectLazyQuery>;
export type MdStoresSelectSuspenseQueryHookResult = ReturnType<typeof useMdStoresSelectSuspenseQuery>;
export type MdStoresSelectQueryResult = Apollo.QueryResult<MdStoresSelectQuery, MdStoresSelectQueryVariables>;
export function refetchMdStoresSelectQuery(variables: MdStoresSelectQueryVariables) {
      return { query: MdStoresSelectDocument, variables: variables }
    }