import * as Types from '~anyx/shared/graphql/base';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MdTimezonesQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type MdTimezonesQuery = { readonly MdTimezones: ReadonlyArray<{ readonly __typename?: 'MdTimezone', readonly updatedAt: string, readonly offset: string, readonly name: string, readonly id: string, readonly createdAt: string }> };


export const MdTimezonesDocument = gql`
    query MdTimezones {
  MdTimezones {
    updatedAt
    offset
    name
    id
    createdAt
  }
}
    `;

/**
 * __useMdTimezonesQuery__
 *
 * To run a query within a React component, call `useMdTimezonesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMdTimezonesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMdTimezonesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMdTimezonesQuery(baseOptions?: Apollo.QueryHookOptions<MdTimezonesQuery, MdTimezonesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MdTimezonesQuery, MdTimezonesQueryVariables>(MdTimezonesDocument, options);
      }
export function useMdTimezonesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MdTimezonesQuery, MdTimezonesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MdTimezonesQuery, MdTimezonesQueryVariables>(MdTimezonesDocument, options);
        }
export function useMdTimezonesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MdTimezonesQuery, MdTimezonesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MdTimezonesQuery, MdTimezonesQueryVariables>(MdTimezonesDocument, options);
        }
export type MdTimezonesQueryHookResult = ReturnType<typeof useMdTimezonesQuery>;
export type MdTimezonesLazyQueryHookResult = ReturnType<typeof useMdTimezonesLazyQuery>;
export type MdTimezonesSuspenseQueryHookResult = ReturnType<typeof useMdTimezonesSuspenseQuery>;
export type MdTimezonesQueryResult = Apollo.QueryResult<MdTimezonesQuery, MdTimezonesQueryVariables>;
export function refetchMdTimezonesQuery(variables?: MdTimezonesQueryVariables) {
      return { query: MdTimezonesDocument, variables: variables }
    }