import { forwardRef, useMemo } from 'react';

import { NumberInputProps, NumberInput } from '@any-ui-react/core';
import { useUncontrolled } from '@mantine/hooks';
import cx from 'classnames';

import { CurrencyUtils, LanguageUtils } from '~anyx/shared/utils';

interface InputPriceProps extends Omit<NumberInputProps, 'defaultValue' | 'value' | 'onChange'> {
  currency?: string;
  defaultValue?: string;
  value?: string;
  onChange?: (e: string) => void;
}

const useFormatPrice = ({ currency }: { currency: string }) => {
  const intlFormatter = useMemo(() => {
    return new Intl.NumberFormat(LanguageUtils.getTwoLettersCode(LanguageUtils.DEFAULT), {
      style: 'currency',
      currency,
    });
  }, [currency]);

  const [thousandsSeparator, decimalSeparator] = useMemo(() => {
    const parts = intlFormatter.formatToParts(1000);

    const thousandsSeparator = parts.find((part) => part.type === 'group')?.value;
    const decimalSeparator = parts.find((part) => part.type === 'decimal')?.value;

    return [thousandsSeparator || ',', decimalSeparator || '.'];
  }, [intlFormatter]);

  const formatter = (val: string) => {
    return intlFormatter
      .formatToParts(Number(val))
      .filter((p) => p.type !== 'currency' && p.type !== 'literal')
      .map((p) => p.value)
      .join('');
  };

  const parser = (val: string) => {
    return val.replace(new RegExp(thousandsSeparator, 'g'), '').replace(decimalSeparator, '.');
  };

  return { formatter, parser, thousandsSeparator, decimalSeparator };
};

export const InputPrice = forwardRef<HTMLInputElement, InputPriceProps>(
  (
    {
      value,
      defaultValue,
      currency = CurrencyUtils.DEFAULT,
      onChange,
      classNames,
      ...rest
    }: InputPriceProps,
    ref
  ) => {
    const [_value, handleChange] = useUncontrolled<string>({
      value,
      defaultValue,
      finalValue: '',
      onChange,
    });

    const { formatter, parser, thousandsSeparator, decimalSeparator } = useFormatPrice({
      currency,
    });

    return (
      <NumberInput
        ref={ref}
        classNames={{
          ...classNames,
          rightSection: cx(classNames?.rightSection, 'w-max'),
        }}
        rightSection={<span className="border-gray-2 border-l pl-1 pr-2">{currency}</span>}
        rightSectionWidth={48}
        min={0}
        thousandsSeparator={thousandsSeparator}
        decimalSeparator={decimalSeparator}
        precision={CurrencyUtils.getRelevantDecimals(currency)}
        parser={parser}
        formatter={formatter}
        value={_value ? Number(_value) : ''}
        onChange={(e) => handleChange(e.toString())}
        {...rest}
      />
    );
  }
);
