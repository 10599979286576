import * as Types from '~anyx/shared/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MdProvincesQueryVariables = Types.Exact<{
  filter?: Types.InputMaybe<Types.MdProvinceFilterInput>;
}>;


export type MdProvincesQuery = { readonly MdProvinces: ReadonlyArray<{ readonly __typename?: 'MdProvince', readonly id: string, readonly countryId: string, readonly name: string, readonly lname: string, readonly country: { readonly __typename?: 'MdCountry', readonly languageId: string } }> };


export const MdProvincesDocument = gql`
    query MdProvinces($filter: MdProvinceFilterInput) {
  MdProvinces(filter: $filter) {
    id
    countryId
    name
    lname
    country {
      languageId
    }
  }
}
    `;

/**
 * __useMdProvincesQuery__
 *
 * To run a query within a React component, call `useMdProvincesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMdProvincesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMdProvincesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMdProvincesQuery(baseOptions?: Apollo.QueryHookOptions<MdProvincesQuery, MdProvincesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MdProvincesQuery, MdProvincesQueryVariables>(MdProvincesDocument, options);
      }
export function useMdProvincesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MdProvincesQuery, MdProvincesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MdProvincesQuery, MdProvincesQueryVariables>(MdProvincesDocument, options);
        }
export function useMdProvincesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<MdProvincesQuery, MdProvincesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<MdProvincesQuery, MdProvincesQueryVariables>(MdProvincesDocument, options);
        }
export type MdProvincesQueryHookResult = ReturnType<typeof useMdProvincesQuery>;
export type MdProvincesLazyQueryHookResult = ReturnType<typeof useMdProvincesLazyQuery>;
export type MdProvincesSuspenseQueryHookResult = ReturnType<typeof useMdProvincesSuspenseQuery>;
export type MdProvincesQueryResult = Apollo.QueryResult<MdProvincesQuery, MdProvincesQueryVariables>;
export function refetchMdProvincesQuery(variables?: MdProvincesQueryVariables) {
      return { query: MdProvincesDocument, variables: variables }
    }