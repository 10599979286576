import { ImgHTMLAttributes } from 'react';

import classNames from 'classnames';

import { NoImgIcon } from '~anyx/shared/icons';

type ImageProps = Omit<ImgHTMLAttributes<HTMLImageElement>, 'src'> & {
  src?: string | null;
  noImageClassName?: string;
  noImageClassNameWrapper?: string;
};

export const Image = ({
  src,
  className,
  noImageClassName,
  noImageClassNameWrapper,
  ...rest
}: ImageProps) =>
  src ? (
    <img
      className={classNames(
        'border-gray-2 text-4xs aspect-square shrink-0 overflow-hidden whitespace-normal rounded-md border object-cover',
        className
      )}
      src={src}
      alt={rest.alt}
      {...rest}
    />
  ) : (
    <div
      className={classNames(
        'border-gray-2 text-4xs bg-gray-0 aspect-square shrink-0 overflow-hidden rounded-md border object-cover p-2',
        noImageClassNameWrapper
      )}
    >
      <NoImgIcon className={classNames('h-full w-full', noImageClassName)} />
    </div>
  );
