import * as Types from '~anyx/shared/graphql';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type SelfDetailsQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type SelfDetailsQuery = { readonly MdSelf: { readonly __typename?: 'MdUser', readonly id: string, readonly accountIds: ReadonlyArray<string>, readonly storeIds: ReadonlyArray<string>, readonly firstName: string, readonly lastName?: string | null, readonly languageId: string, readonly currencyId: string, readonly timezoneId: string, readonly role: Types.MdUserRole, readonly email: string, readonly availableModules: ReadonlyArray<Types.MdAnyXModuleEnum>, readonly canManageUsers: boolean, readonly accessAllStores: boolean, readonly accounts: ReadonlyArray<{ readonly __typename?: 'MdAccount', readonly id: string, readonly name: string }> } };


export const SelfDetailsDocument = gql`
    query SelfDetails {
  MdSelf {
    id
    accountIds
    accounts {
      id
      name
    }
    storeIds
    firstName
    lastName
    languageId
    currencyId
    timezoneId
    role
    email
    availableModules
    canManageUsers
    accessAllStores
  }
}
    `;

/**
 * __useSelfDetailsQuery__
 *
 * To run a query within a React component, call `useSelfDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSelfDetailsQuery(baseOptions?: Apollo.QueryHookOptions<SelfDetailsQuery, SelfDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelfDetailsQuery, SelfDetailsQueryVariables>(SelfDetailsDocument, options);
      }
export function useSelfDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelfDetailsQuery, SelfDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelfDetailsQuery, SelfDetailsQueryVariables>(SelfDetailsDocument, options);
        }
export function useSelfDetailsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SelfDetailsQuery, SelfDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SelfDetailsQuery, SelfDetailsQueryVariables>(SelfDetailsDocument, options);
        }
export type SelfDetailsQueryHookResult = ReturnType<typeof useSelfDetailsQuery>;
export type SelfDetailsLazyQueryHookResult = ReturnType<typeof useSelfDetailsLazyQuery>;
export type SelfDetailsSuspenseQueryHookResult = ReturnType<typeof useSelfDetailsSuspenseQuery>;
export type SelfDetailsQueryResult = Apollo.QueryResult<SelfDetailsQuery, SelfDetailsQueryVariables>;
export function refetchSelfDetailsQuery(variables?: SelfDetailsQueryVariables) {
      return { query: SelfDetailsDocument, variables: variables }
    }